.zoho-demo-form {
  height: 650px;
  width: 70%;
  border: none;
  justify-content: center !important;
}

@media (max-width: 575.98px) {
  .zoho-demo-form {
    width: 95%;
  }
}

#workAddress {
  display: none;
}

@media (max-width: 575.98px) {
  .videoContainerMobile {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 575.98px) {
  .u-pull-up {
    top: -30px;
  }
}

.main-shadow {
  box-shadow: 2px 1px 36px rgb(0 0 0 / 32%);
}
.card {
  transition: transform 0.2s ease;
  box-shadow: 0px 10px 20px 0px rgba(22, 22, 26, 0.12);
  border-radius: 0;
  border: 0;
  margin-bottom: 1.5em;
}

/* .card:hover {
  transform: scale(1.1);
} */

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  overflow: hidden;
}

.video {
  pointer-events: none;
  width: 100%;
}

.mobileFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999;
  background-color: #fff;
  box-shadow: 0 1rem 1rem 1.5rem rgba(0, 0, 0, 0.1) !important;
}

.font-face-ar h1 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar h4 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar h2 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar p {
  text-align: right;
  font-weight: 500;
}

.featureHeader {
  font-size: 90px;
  padding-top: 20px !important;
}

@media (max-width: 575.98px) {
  .featureHeader {
    font-size: 50px;
    padding-top: 20px !important;
    font-weight: 600 !important;
  }
}

.navbar > .container-fluid .navbar-brand {
  margin-left: 0;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}

.text-light-black {
  color: #303030;
}

.features {
  padding: 15px;
  margin-top: 30px;
}

.features img {
  width: 100%;
  object-fit: cover;
  /* background-color: white; */
}

.features .top-sec {
  margin-top: -100px;
  margin-bottom: 15px;
}

input[type='file'] {
  width: 0;
  height: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
  opacity: 0;
}

@media (max-width: 991px) {
  #navbarNav {
    background: #f8f8f8;
    max-height: 100vh;
    margin-top: 100;
    padding: 20px;
    overflow: auto;
  }
  #navbarNav .nav-link {
    color: #54667a;
  }
}

.navbar-default {
  background-color: #69899f;
  background-image: linear-gradient(to bottom, #fff 0%, #fff 94%);
  box-shadow: none;
}

.suggestion-item {
  padding: 10px;
  text-align: left;
  background-color: #fafafa;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 10px;
  text-align: left;
  background-color: #bababa;
  cursor: pointer;
}

.panel {
  background-color: #ececec6b;
}

.panel-footer {
  background-color: #ececec6b;
}

.timeline {
  background-color: #fff;
}

.white-bg {
  background-color: #fff;
}
.pad-top-45 {
  padding-top: 45px;
}

.pad-top-35 {
  padding-top: 35px;
}

.pad-top-30 {
  padding-top: 30px;
}

.fileupload {
  position: relative;
  overflow: hidden;
}

.text-height-0 {
  line-height: 1 !important;
}

.text-height-1 {
  line-height: 1.4 !important;
}

.borderedCard {
  border: 2px solid #e9eff5;
  border-radius: 4px;
}

.fa-stack:after {
  position: absolute;
  right: 10%;
  top: 30%;
  content: attr(data-count);
  font-size: 30%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: #66b400;
  min-width: 1em;
  font-weight: bold;
}

.darkBorder {
  border: 1px solid #525252 !important;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.67em + 1.57rem + 2px);
  padding: 0.785rem 2.67rem 0.785rem 1.67rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.67;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 1.67rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 0.7rem 0.8rem rgba(0, 0, 0, 0.1);
}

.main {
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2) !important;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0;
}

.pricing .card-discounted-price {
  font-size: 1.15rem;
  text-decoration: line-through;
  color: #fc3158 !important;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.7rem 1rem 0 rgba(0, 0, 0, 0.5) !important;
  }

  .pricing .card:hover .btn {
    opacity: 1 !important;
  }

  .card .btn-primary:hover {
    background: #4f64ff;
    border-color: #4f64ff;
  }
}

.Modal {
  position: absolute;
  top: 10px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #4f64ff;
  border-radius: 15px;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ModalClose {
  position: absolute;
  top: 10px;
  right: 0px;
}

.offerModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 200px;
  max-height: 400px;
  border-radius: 15px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .offerModal {
    min-height: calc(50% - 1rem);
    min-width: calc(100% - 1rem);
  }
}
.offerModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.offerModalClose {
  padding: 10px;
  align-items: flex-start;
}

.faq-row-wrapper {
  max-width: 700px;
}
.faq-title {
  justify-content: center !important;
  border-bottom: none !important;
}
.faq-body {
  padding-top: 20px;
}

@media (max-width: 991px) {
  .faq-body {
    padding-top: 20px;
  }
}

.row-content-text {
  padding: 10px !important;
}
