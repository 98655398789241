*,
:after,
:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}

body,
html {
  height: 100%;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
}

h1 {
  min-height: 1rem;
  font-size: 2rem;
}

h2 {
  font-size: 1.71428571rem;
}

h5 {
  font-size: 1rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0;
}

p {
  margin: 0 0 1em;
  line-height: 1.4285em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: #4183c4;
  text-decoration: none;
}

a:hover {
  color: #1e70bf;
  text-decoration: none;
}

@font-face {
  font-family: 'Almarai';
  src: url(/static/media/Almarai-Regular.4fcf5636.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Almarai';
  src: url(/static/media/Almarai-Bold.1c7b8f3e.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'DMSans';
  src: url(/static/media/DMSans-Regular.7c217bc9.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DMSans';
  src: url(/static/media/DMSans-Medium.24bfda97.ttf) format('truetype');
  font-weight: medium;
  font-display: swap;
}

@font-face {
  font-family: 'DMSans';
  src: url(/static/media/DMSans-Bold.b9cec521.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'BarlowSemiCondensed';
  src: url(/static/media/BarlowSemiCondensed-Regular.ebd90d8e.ttf)
    format('truetype');
  font-weight: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
}

.green {
  color: green;
}

.popper {
  z-index: 100 !important;
}

.navbar {
  margin-bottom: 0px;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: '\A0\A0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\39F';
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.flag-select img {
  padding-bottom: 7px !important;
  width: 2.3em !important;
  height: 2.3em !important;
}

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}

.blockquote-footer::before {
  content: '\2014   \A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e9ecef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e9ecef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.thead-inverse th {
  color: #fff;
  background-color: #212529;
}

.thead-default th {
  color: #495057;
  background-color: #e9ecef;
}

.table-inverse {
  color: #fff;
  background-color: #212529;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #32383e;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-inverse.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-inverse.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive.table-bordered {
    border: 0;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
}

.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip,
.form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip,
.was-validated .custom-select:valid ~ .invalid-feedback,
.was-validated .custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label,
.form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator,
.custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description,
.custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control,
.custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before,
.custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus,
.custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label,
.form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator,
.custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description,
.custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control,
.custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before,
.custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus,
.custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:active,
.btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0069d9;
  background-image: none;
  border-color: #0062cc;
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #727b84;
  background-image: none;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #165f1f;
  border-color: #165f1f;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #218838;
  background-image: none;
  border-color: #1e7e34;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #138496;
  background-image: none;
  border-color: #117a8b;
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #e0a800;
  background-image: none;
  border-color: #d39e00;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #c82333;
  background-image: none;
  border-color: #bd2130;
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: #e2e6ea;
  background-image: none;
  border-color: #dae0e5;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #23272b;
  background-image: none;
  border-color: #1d2124;
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link:disabled {
  background-color: transparent;
}

.btn-link,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.show > a {
  outline: 0;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
  margin-bottom: 0;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical
  > .btn-group:first-child:not(:last-child)
  > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical
  > .btn-group:last-child:not(:first-child)
  > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle='buttons'] > .btn input[type='radio'],
[data-toggle='buttons'] > .btn input[type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn input[type='radio'],
[data-toggle='buttons'] > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  width: 100%;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group .form-control:focus,
.input-group .form-control:active,
.input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child)
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:focus,
.input-group-btn > .btn:active,
.input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn:focus,
.input-group-btn:not(:first-child) > .btn:active,
.input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.custom-file-control:lang(en):empty::after {
  content: 'Choose file...';
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-control:lang(en)::before {
  content: 'Browse';
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.show > .nav-pills .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: white;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb::after {
  display: block;
  clear: both;
  content: '';
}

.breadcrumb-item {
  float: left;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #868e96;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus,
.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #111;
  background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #111;
  background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}

.alert-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-secondary .alert-link {
  color: #2e3133;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus,
.list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}

a.list-group-item-primary:focus,
a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}

a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #155724;
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460;
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404;
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24;
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #818182;
}

a.list-group-item-light:focus,
a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}

a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}

.tooltip.bs-tooltip-top,
.tooltip.bs-tooltip-auto[x-placement^='top'] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-top .arrow,
.tooltip.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  margin-left: -3px;
  content: '';
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.bs-tooltip-right,
.tooltip.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-right .arrow,
.tooltip.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
}

.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  margin-top: -3px;
  content: '';
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.bs-tooltip-bottom,
.tooltip.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow,
.tooltip.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  margin-left: -3px;
  content: '';
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bs-tooltip-left,
.tooltip.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-left .arrow,
.tooltip.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
}

.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: '';
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow::before {
  content: '';
  border-width: 11px;
}

.popover .arrow::after {
  content: '';
  border-width: 11px;
}

.popover.bs-popover-top,
.popover.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 10px;
}

.popover.bs-popover-top .arrow,
.popover.bs-popover-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^='top'] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-bottom-width: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^='top'] .arrow::before {
  bottom: -11px;
  margin-left: -6px;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^='top'] .arrow::after {
  bottom: -10px;
  margin-left: -6px;
  border-top-color: #fff;
}

.popover.bs-popover-right,
.popover.bs-popover-auto[x-placement^='right'] {
  margin-left: 10px;
}

.popover.bs-popover-right .arrow,
.popover.bs-popover-auto[x-placement^='right'] .arrow {
  left: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^='right'] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^='right'] .arrow::after {
  margin-top: -8px;
  border-left-width: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^='right'] .arrow::before {
  left: -11px;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^='right'] .arrow::after {
  left: -10px;
  border-right-color: #fff;
}

.popover.bs-popover-bottom,
.popover.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 10px;
}

.popover.bs-popover-bottom .arrow,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  margin-left: -7px;
  border-top-width: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  top: -11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  top: -10px;
  border-bottom-color: #fff;
}

.popover.bs-popover-bottom .popover-header::before,
.popover.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.popover.bs-popover-left,
.popover.bs-popover-auto[x-placement^='left'] {
  margin-right: 10px;
}

.popover.bs-popover-left .arrow,
.popover.bs-popover-auto[x-placement^='left'] .arrow {
  right: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^='left'] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^='left'] .arrow::after {
  margin-top: -8px;
  border-right-width: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^='left'] .arrow::before {
  right: -11px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^='left'] .arrow::after {
  right: -10px;
  border-left-color: #fff;
}

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 9px 14px;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (
  (transform-style: preserve-3d)
) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (
  (transform-style: preserve-3d)
) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (
  (transform-style: preserve-3d)
) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:focus,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: '';
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: '';
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}
/*# sourceMappingURL=bootstrap.css.map */

.font-face-ar {
  font-family: 'Almarai', Helvetica, sans-serif;
}

.font-face-ar {
  font-family: 'Almarai', Helvetica, sans-serif !important;
}

.font-face-en {
  font-family: 'BarlowSemiCondensed', Helvetica, sans-serif !important;
}

body {
  font-family: 'BarlowSemiCondensed', Helvetica, sans-serif !important;
  font-size: 1.3rem;
  line-height: 1.875em;
  color: #5a6671;
  font-weight: 500;
}

.heroBg {
  /*background-color: #4d6cfa !important;*/
  background-position: top right;
  background-size: 50%;
}

.heroImage {
  width: 41%;
  height: 50%;
}

@media (max-width: 575.98px) {
  .heroImage {
    width: 85%;
    height: 50%;
  }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.bar .progress {
  background: inherit;
}

.phoneInput img {
  vertical-align: top;
}

.modal-image {
  width: 90%;
  height: 90%;
}

.invetory-modal-image {
  width: 60%;
  height: 70%;
}

@media (max-width: 575.98px) {
  .modal-image {
    width: 100%;
    height: 100%;
  }
}

.client-image {
  width: 60%;
  height: 60%;
}

.label-image {
  max-width: 135px;
  max-height: 40px;
}

.zakatLogo {
  max-width: 235px;
  max-height: 90px;
}

@media (max-width: 575.98px) {
  .label-image {
    max-width: 120px;
    max-height: 80px;
    padding-top: 20px;
    align-items: left;
    margin-left: 10px;
  }

  .labels_row {
    padding-left: 5px !important;
    margin-left: 5px !important;
  }
}

.phoneInput .react-phone-number-input__icon {
  border: none;
}

@media (max-width: 575.98px) {
  .main-container {
    padding-top: 200px;
  }
}

.shape_1 {
  position: absolute;
  width: 260px;
  left: -80px;
  bottom: -140px;
  z-index: -2;
}

@media (max-width: 575.98px) {
  .shape_1 {
    left: -70px;
    bottom: 10px;
  }
}

.shape_2 {
  position: absolute;
  width: 260px;
  left: -20px;
  top: -50px;
  z-index: -2;
}

@media (max-width: 575.98px) {
  .shape_2 {
    left: -70px;
    top: -180px;
  }
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.footer-logo {
  width: 163px;
  height: 56px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000000;
  line-height: 1.37em;
}

h1,
.h1 {
  font-weight: bold;
  font-size: 40px;
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 30px;
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-size: 26px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 20px;
}

a {
  color: inherit;
  transition: 0.3s;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

/*button,
input,
optgroup,
select,
textarea {
  font-family: 'Almarai', sans-serif;
}
*/
b,
strong {
  font-weight: 600;
  color: #273f59;
}

sup {
  top: -1.3em;
}

sub {
  bottom: -1.3em;
}

section {
  padding-top: 150px;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
}
@media (max-width: 991px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

input,
textarea {
  border: 1px solid #e9eff5;
  padding: 9px 30px;
  border-radius: 4px;
  width: 100%;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}

@media (max-width: 767px) {
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 3rem;
  }
  .display-4 {
    font-size: 2.5rem;
  }
}

.show-onHover {
  opacity: 0;
  transition: 0.3s;
}

.show-onHover-parent:hover .show-onHover {
  opacity: 1;
}

[data-dark-overlay] {
  position: relative;
}
[data-dark-overlay]:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: -1;
}

[data-dark-overlay='1']:after {
  opacity: 0.1;
}

[data-dark-overlay='2']:after {
  opacity: 0.2;
}

[data-dark-overlay='3']:after {
  opacity: 0.3;
}

[data-dark-overlay='4']:after {
  opacity: 0.4;
}

[data-dark-overlay='5']:after {
  opacity: 0.5;
}

[data-dark-overlay='6']:after {
  opacity: 0.6;
}

[data-dark-overlay='7']:after {
  opacity: 0.7;
}

[data-dark-overlay='8']:after {
  opacity: 0.8;
}

[data-parallax] {
  background-color: transparent;
}

.typed-curcor {
  -webkit-animation-name: blinker;
  animation-name: blinker;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 1px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.is-scrolling .hamburger-inner,
.is-scrolling .hamburger-inner::before,
.is-scrolling .hamburger-inner::after {
  background-color: #54667a;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -9px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.3s;
}

.navbar-brand {
  position: relative;
}

.logo-inverse {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.navbar-nav > .nav-item {
  margin-left: 15px;
}
.navbar-nav > .nav-item > .nav-link {
  font-weight: 600;
  font-size: 18px;
}
.navbar-nav > .nav-item > .nav-link.active {
  color: #3564ff;
}

.navbar-toggler {
  color: #fff;
  position: relative;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler * {
  transition: 0.5s;
}
.navbar-toggler[aria-expanded='true'] .nav-hamburger {
  opacity: 0;
}
.navbar-toggler[aria-expanded='true'] .nav-close {
  opacity: 1;
}
.navbar-toggler[aria-expanded='false'] .nav-hamburger {
  opacity: 1;
}
.navbar-toggler .nav-close {
  position: absolute;
  opacity: 0;
  top: 3px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu,
.dropdown__col-2 {
  border: none !important;
  transition: 0.5s;
  padding: 15px;
  min-width: 13rem;
}

.dropdown-item {
  border-radius: 4px;
  padding: 5px 15px;
  text-transform: capitalize;
}
.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:active {
  background: #3564ff;
  color: #fff;
}

@media (min-width: 992px) {
  .dropdown-menu {
    display: block;
    border: none;
    margin-top: 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    padding: 15px;
    transform: translateX(-50%);
  }
  .dropdown-menu.show {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
  .dropdown:hover > .dropdown-menu,
  .dropdown:hover .dropdown__col-2 {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 1030;
  }
}

@media (min-width: 992px) {
  .dropdown__col-2 {
    min-width: 450px;
    display: flex !important;
    flex-wrap: wrap;
  }
  .dropdown__col-2 .dropdown-item {
    flex-basis: 50%;
    font-size: 15px;
  }
}

.header-inverse {
  transition: 0.5s;
}
.header-inverse .nav-link {
  color: #fff;
}
.header-inverse-false {
  background: #fff;
}
.header-inverse-false .logo-default {
  opacity: 0;
}
.header-inverse-false .logo-inverse {
  opacity: 1;
}

.is-scrolling .fixed-top {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.is-scrolling .navbar-toggler {
  color: #54667a;
}

.is-scrolling .header-inverse {
  background: #fff !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.is-scrolling .header-inverse .nav-link {
  color: #54667a;
}

.is-scrolling .header-shrink {
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.is-scrolling .logo-default {
  opacity: 0;
  transition: 0.3s;
}

/*.logo-inverse img {
  width: 130;
  height: 40;
}

.logo-default img {
  width: 130;
  height: 40px;
}*/

.is-scrolling .logo-inverse {
  opacity: 1;
}

.header-shadow {
  box-shadow: 0 3px 20px 0 rgba(0, 129, 255, 0.1);
}

@media (max-width: 991px) {
  #navbarNav {
    background: #fff !important;
    max-height: 100vh;
    padding: 20px;
    overflow: auto;
  }
  #navbarNav .nav-link {
    color: #000;
  }
  #navbarNav .navbar-nav > .nav-item > .nav-link.active {
    color: #3564ff !important;
  }
}

.intro-hero {
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover !important;
  position: relative;
  z-index: 10;
}

.social a {
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.1);
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
  line-height: 45px;
}
.social a:hover {
  background: #339aff;
}

.social.social-white a {
  background: #fff;
  color: #92b7db;
}
.social.social-white a:hover {
  color: #3564ff;
}

.social-rounded a {
  border-radius: 50%;
}

.social-default a {
  background: #ecf5fe;
  color: #92b7db;
}
.social-default a:hover {
  color: #3564ff;
  background: #ecf5fe;
}

.social-sm a {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}

.scroll-top {
  bottom: 120px;
  font-size: 20px;
  height: 40px;
  position: fixed;
  text-align: center;
  width: 40px;
  z-index: 10;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  line-height: 40px;
  right: -100px;
}
.scroll-top.active {
  right: 41px;
}

.btn {
  cursor: pointer;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px !important;
  padding: 0.8125rem 2rem;
}
.btn:focus {
  box-shadow: none;
}

.btn-rounded {
  border-radius: 100em;
}

.btn-primary {
  background: #3564ff;
  border-color: #3564ff;
  box-shadow: none;
  color: #fff;
}
.btn-primary:hover {
  background: #6f80fd;
  box-shadow: none;
  border-color: #6f80fd;
}

.btn-white {
  background: #fff;
  box-shadow: 0 5px 40px 0 rgba(32, 101, 169, 0.2);
  color: #597187 !important;
}
.btn-white:hover {
  background: #455768;
  color: #fff !important;
}

.btn-outline-white {
  background: rgba(44, 212, 74, 0.8);
  border-color: rgba(44, 212, 74, 0.8);
  color: #fff !important;
  text-transform: none;
  letter-spacing: 0.7px;
}

.btn-outline-white:hover {
  background: rgba(44, 212, 74, 0.8);
  border-color: rgba(44, 212, 74, 0.8);
  color: #222222 !important;
  font-weight: 900;
}

.btn-pulple-light {
  background: #a282e3;
  color: #fff;
  box-shadow: 0 5px 40px 0 rgba(162, 130, 227, 0.4);
}
.btn-pulple-light:hover {
  background: #8358da;
  color: #fff;
}

.btn-outline-pulple-light {
  background: transparent;
  border-color: #a282e3;
}
.btn-outline-pulple-light:hover {
  background: #a282e3;
  color: #fff;
}

.btn-yellow {
  background: #fcc851;
  box-shadow: 0 5px 40px 0 rgba(255, 173, 0, 0.3);
  color: #fff !important;
}
.btn-yellow:hover {
  background: #fcc851;
  color: #fff;
}

.btn-blue {
  background: #33bbff;
  box-shadow: 0 5px 40px 0 rgba(106, 205, 255, 0.3);
  color: #fff !important;
}
.btn-blue:hover {
  background: #6acdff;
  color: #fff;
}

.btn-outline-yellow {
  background: transparent;
  border-color: #fcc851;
}
.btn-outline-yellow:hover {
  background: #fcc851;
  color: #fff;
}

.btn-green {
  background: #22891f;
  box-shadow: 0 5px 40px 0 rgba(44, 212, 74, 0.3);
  color: #fff !important;
}
.btn-green:hover {
  background: #27bf42;
  color: #fff;
}

.btn-red {
  background: #f64d62;
  box-shadow: 0 5px 40px 0 rgba(246, 77, 98, 0.3);
  color: #fff !important;
}
.btn-red:hover {
  background: #f5354d;
  color: #fff;
}

.btn-outline-green {
  background: transparent;
  border-color: #2cd44a;
}
.btn-outline-green:hover {
  background: #27bf42;
  color: #fff;
}

.btn-sm {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px !important;
}

.subsribe-rounded .form-control {
  border-color: #dde9f5;
}
@media (min-width: 576px) {
  .subsribe-rounded .form-control {
    min-width: 460px;
  }
}

@media (min-width: 768px) {
  .subsribe-rounded .btn {
    margin-left: -50px;
  }
}

.accordion__faq {
  border-bottom: 1px solid #eaecef;
}

.accordion__title {
  font-size: 20px;
  color: #273f59;
  font-weight: 600;
  display: block;
}
.accordion__title > i {
  transition: 0.5s;
}
.accordion__title.active > i {
  transform: rotate(180deg);
}

.accordion__text {
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  flex-grow: 1;
}

.tabs-v1 .bg-white {
  color: #476483;
}
.tabs-v1 .bg-white.active {
  color: #273f59;
}

.tabs-v2 {
  background: #fff;
  border-radius: 100em;
  border-bottom: none;
}
@media (max-width: 767px) {
  .tabs-v2 {
    display: block !important;
    border-radius: 0 !important;
  }
}
.tabs-v2 > .nav-item {
  flex: 1 1;
}
.tabs-v2 .nav-link {
  border-radius: 100em;
  text-align: center;
  padding: 10px;
}
.tabs-v2 .nav-link.active {
  background: #3564ff;
  color: #fff;
}

.tabs-v3 .nav-link {
  background: #ecf5fe;
}
@media (min-width: 600px) {
  .tabs-v3 .nav-link {
    width: 200px;
  }
}
.tabs-v3 .nav-link.active {
  background: #3564ff;
  border-color: transparent;
  color: #fff;
}

.portfolio-block {
  position: relative;
  margin-top: 30px;
}
.portfolio-block:hover .portfolio-overlay {
  opacity: 1;
}
.portfolio-block:hover .portfolio-desc {
  margin-top: 30px;
  opacity: 1;
}

.portfolio-overlay {
  background: #3564ff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;
  opacity: 0;
  transition: 0.3s;
}

.portfolio-btn-action > a {
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 20px;
  border: 1px solid rgba(244, 244, 244, 0.5);
  border-radius: 50%;
  padding: 11px;
  text-align: center;
  margin: 5px;
}
.portfolio-btn-action > a:hover,
.portfolio-btn-action > a:focus {
  color: #fff;
  background: rgba(244, 244, 244, 0.5);
  outline: none;
}

.portfolio-desc {
  margin-top: 100px;
  opacity: 0;
  transition: 0.3s;
}

.portfolio-details-text > h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: normal;
  color: #000;
}

.portfolio-meta {
  padding: 30px;
}
.portfolio-meta ul li {
  padding: 5px;
}
.portfolio-meta ul li strong {
  margin-right: 5px;
  color: #000;
}
.portfolio-meta ul li > a {
  display: inline-block;
  margin: 0 5px;
}

.portfolio-filter {
  border-radius: 50em;
  overflow: hidden;
}
.portfolio-filter > li {
  padding: 10px 30px;
  border-radius: 50em;
  cursor: pointer;
}
.portfolio-filter > li.active {
  color: #fff;
  background: #3564ff;
}

.post {
  margin-bottom: 40px;
}

.post__content {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .post__content {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
  }
}

.post__date {
  background: #fff;
  transform: translateY(-50%);
}

.post__share {
  float: right;
  transform: translateY(-50%);
  position: relative;
}

.post__share-media {
  position: absolute;
  right: 100%;
  top: 0;
  min-width: 200px;
  text-align: right;
}
.post__share-media > a {
  display: inline-block;
}

.share__icon {
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.post-share-and-taq {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.post-taq > a {
  padding: 5px;
  display: inline-block;
}

.sidebar-widget,
.widget,
.widget_recent_entries,
.widget_calendar,
.widget_archive,
.widget_recent_entries,
.widget_recent_comments,
.widget_categories,
.widget_text,
.widget_pages,
.widget_meta,
.widget_recent_entries,
.widget_rss {
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  background: #fff;
  margin-bottom: 40px;
  border-radius: 4px;
  padding: 30px;
}

.side-post-block {
  padding-bottom: 20px;
}

.side-post-img {
  float: left;
  margin-right: 30px;
}
.side-post-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.side-post-content > h4 {
  font-size: 20px;
}

.side-post-content .post-date {
  font-size: 14px;
}

.post__tab-content {
  display: none;
}
.post__tab-content.active {
  display: block;
}

.post-tab-list > h2 {
  width: 50%;
  float: left;
  text-align: center;
}
.post-tab-list > h2 a.active {
  color: #7b8697;
}

.sidebar-widget-title,
.widget > h2,
.widget-title {
  font-size: 24px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.tags-list > a {
  display: inline-block;
  color: #777;
  text-transform: capitalize;
  padding: 3px 15px;
  background: #f8f8f8;
  margin-right: 5px;
  border-radius: 50px;
  margin-bottom: 10px;
}
.tags-list > a:hover {
  background: #3564ff;
  color: #fff;
}

/***************************WordPress Default Widget style
/***************************/
.widget ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.widget ul > li {
  margin-bottom: 5px;
}
.widget ul > li > a .badge {
  background: #cce6ff;
  color: #6685a4;
  border-radius: 10em;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
  transition: 0.3s;
}
.widget ul > li > a:hover {
  color: #3564ff;
}
.widget ul > li > a:hover .badge {
  background: #3564ff;
  color: #fff;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.ec-comments-area .comments-title {
  font-size: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}

.ec-comments-area .comment-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}

.ec-comments-area .comment-main-area {
  margin-left: 90px;
}

.ec-comments-area .comment {
  padding-bottom: 30px;
}

.ec-comments-area .ec-comments-meta > h4 {
  font-size: 16px;
}

.ec-comments-area .comments-date {
  color: #9c9c9c;
  margin-bottom: 15px;
  display: inline-block;
}

.ec-comments-area .comments .children {
  margin-left: 20px;
  border-top: 1px solid #eee;
  padding-top: 50px;
  padding-bottom: 30px;
  list-style: none;
}

.ec-comments-area .comments-reply {
  float: right;
}

.ec-comments-area .ec-comment-form {
  margin-top: 30px;
}

.ec-comment-form .comment-reply-title {
  font-size: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}

@media (max-width: 991px) {
  .share-post > p > span {
    display: block;
    margin-bottom: 10px;
  }
}

.progress-bar {
  width: 0;
  transition: 0.5s;
}

.u-as-parent {
  width: 100% !important;
  height: 100% !important;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  z-index: -1;
}

.u-ff-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.u-of-hidden {
  overflow: hidden;
}

.u-bg-img {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.u-bg-img-repeat-x {
  background-repeat: repeat-x !important;
}
.u-bg-img-repeat-y {
  background-repeat: repeat-y !important;
}

.u-bg-parallax,
.u-bg-fixed {
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.u-bg-left-center {
  background-position: left center !important;
}

.u-bg-top-center {
  background-position: top center !important;
}

.u-bg-bottom-center {
  background-position: bottom center !important;
}

.u-bg-transparent {
  background: transparent !important;
}

.u-bdr-x {
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}
.u-bdr-x-0 {
  border-left: none !important;
  border-right: none !important;
}

.u-bdr-y {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.u-bdr-y-0 {
  border-top: none !important;
  border-bottom: none !important;
}

.u-bdr-top {
  border-top: 1px solid transparent !important;
}

.u-bdr-bottom {
  border-bottom: 1px solid transparent !important;
}

.u-bdr-left {
  border-left: 1px solid transparent !important;
}

.u-bdr-right {
  border-right: 1px solid transparent !important;
}

.u-bdr-dashed {
  border: 1px dashed transparent !important;
}
.u-bdr-dashed--y {
  border-top: 1px dashed transparent !important;
  border-bottom: 1px dashed transparent !important;
}
.u-bdr-dashed--x {
  border-right: 1px dashed transparent !important;
  border-left: 1px dashed transparent !important;
}
.u-bdr-dashed--top {
  border-top: 1px dashed #c0c0c8 !important;
}
.u-bdr-dashed--bottom {
  border-bottom: 1px dashed #c0c0c8 !important;
}
.u-bdr-dashed--left {
  border-left: 1px dashed transparent !important;
}
.u-bdr-dashed--right {
  border-right: 1px dashed transparent !important;
}

.u-bdr-dotted {
  border: 1px dotted transparent !important;
}
.u-bdr-dotted--y {
  border-top: 1px dotted transparent !important;
  border-bottom: 1px dotted transparent !important;
}
.u-bdr-dotted--x {
  border-right: 1px dotted transparent !important;
  border-left: 1px dotted transparent !important;
}
.u-bdr-dotted--top {
  border-top: 1px dashed dotted !important;
}
.u-bdr-dotted--bottom {
  border-bottom: 1px dotted transparent !important;
}
.u-bdr-dotted--left {
  border-left: 1px dotted transparent !important;
}
.u-bdr-dotted--right {
  border-right: 1px dotted transparent !important;
}

@media (min-width: 768px) {
  .u-bdr-md {
    border: 1px solid transparent !important;
  }
  .u-bdr-md-top {
    border-top: 1px solid transparent !important;
  }
  .u-bdr-md-bottom {
    border-bottom: 1px solid transparent !important;
  }
  .u-bdr-md-left {
    border-left: 1px solid transparent !important;
  }
  .u-bdr-md-right {
    border-right: 1px solid transparent !important;
  }
}

@media (min-width: 992px) {
  .u-bdr-lg {
    border: 1px solid transparent !important;
  }
  .u-bdr-lg-top {
    border-top: 1px solid transparent !important;
  }
  .u-bdr-lg-bottom {
    border-bottom: 1px solid transparent !important;
  }
  .u-bdr-lg-left {
    border-left: 1px solid transparent !important;
  }
  .u-bdr-lg-right {
    border-right: 1px solid transparent !important;
  }
}

.u-bdr-1 {
  border-width: 1px !important;
}

.u-bdr-2 {
  border-width: 2px !important;
}

.u-bdr-3 {
  border-width: 3px !important;
}

.u-bdr-4 {
  border-width: 4px !important;
}

.u-bdr-5 {
  border-width: 5px !important;
}

.u-rounded-0 {
  border-radius: 0px !important;
}

.u-rounded {
  border-radius: 10px;
}

.u-rounded-4 {
  border-radius: 4px !important;
}

.u-rounded-5 {
  border-radius: 5px !important;
}

.u-rounded-6 {
  border-radius: 6px !important;
}

.u-rounded-7 {
  border-radius: 7px !important;
}

.u-rounded-8 {
  border-radius: 8px !important;
}

.u-rounded-9 {
  border-radius: 9px !important;
}

.u-rounded-10 {
  border-radius: 10px !important;
}

.u-rounded-15 {
  border-radius: 15px !important;
}

.u-rounded-20 {
  border-radius: 20px !important;
}

.u-rounded-25 {
  border-radius: 25px !important;
}

.u-rounded-30 {
  border-radius: 30px !important;
}

.u-rounded-50 {
  border-radius: 1000em;
}

.u-rounded-left-50 {
  border-top-left-radius: 1000em;
  border-bottom-left-radius: 1000em;
}

.u-rounded-right-50 {
  border-top-right-radius: 1000em;
  border-bottom-right-radius: 1000em;
}

.u-bdrs-top-4 {
  border-radius: 4px 4px 0 0 !important;
}

.u-bdrs-top-6 {
  border-radius: 6px 6px 0 0 !important;
}

.u-bdrs-top-8 {
  border-radius: 8px 8px 0 0 !important;
}

.u-rounded-top {
  border-radius: 25px 25px 0 0 !important;
}

.u-rounded-bottom {
  border-radius: 0 0 25px 25px !important;
}

.u-rounded-bottom-left {
  border-radius: 0 0 0 25px !important;
}

.u-rounded-bottom-right {
  border-radius: 0 0 25px 0 !important;
}

.u-rounded-bottom-4 {
  border-radius: 0 0 4px 4px !important;
}

.u-rounded-bottom-6 {
  border-radius: 0 0 6px 6px !important;
}

.u-rounded-bottom-8 {
  border-radius: 0 0 8px 8px !important;
}

.u-rounded-right-4 {
  border-radius: 0 4px 4px 0 !important;
}

.u-rounded-right-6 {
  border-radius: 0 6px 6px 0 !important;
}

.u-rounded-right-8 {
  border-radius: 0 8px 8px 0 !important;
}

.u-rounded-left-4 {
  border-radius: 4px 0 0 4px !important;
}

.u-rounded-left-6 {
  border-radius: 6px 0 0 6px !important;
}

.u-rounded-left-8 {
  border-radius: 8px 0 0 8px !important;
}

.box-shadow-v1 {
  box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
}
.box-shadow-v1--onHover {
  transition: 0.3s;
}
.box-shadow-v1--onHover:hover {
  box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
}

.box-shadow-v2 {
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
}
.box-shadow-v2--onHover {
  transition: 0.3s;
}
.box-shadow-v2--onHover:hover {
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
}

.box-shadow-v3 {
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}
.box-shadow-v3--onHover {
  transition: 0.3s;
}
.box-shadow-v3--onHover:hover {
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.box-shadow-v4 {
  box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
}
.box-shadow-v4--onHover {
  transition: 0.3s;
}
.box-shadow-v4--onHover:hover {
  box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
}

.box-shadow-primary {
  box-shadow: 0 5px 30px 0 rgba(0, 129, 255, 0.3);
}
.box-shadow-primary--onHover {
  transition: 0.3s;
}
.box-shadow-primary--onHover:hover {
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.box-shadow-yellow {
  box-shadow: 0 5px 30px 0 #fcc851;
}
.box-shadow-primary--onHover {
  transition: 0.3s;
}
.box-shadow-primary--onHover:hover {
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.u-w-50vh {
  width: 50vh !important;
}

.u-w-100vh {
  width: 100vh !important;
}

.u-w-30p {
  width: 30% !important;
}

.u-w-40p {
  width: 40% !important;
}

.u-w-60p {
  width: 60% !important;
}

.u-w-70p {
  width: 70% !important;
}

.u-w-80p {
  width: 80% !important;
}

.u-w-90p {
  width: 90% !important;
}

.u-w-100p {
  width: 100% !important;
}

.u-flex-1 {
  flex-grow: 1 !important;
}

.u-w-5 {
  width: 0.3125rem !important;
}

.u-w-10 {
  width: 0.625rem !important;
}

.u-w-15 {
  width: 0.9375rem !important;
}

.u-w-20 {
  width: 1.25rem !important;
}

.u-w-25 {
  width: 1.5625rem !important;
}

.u-w-30 {
  width: 1.875rem !important;
}

.u-w-35 {
  width: 2.1875rem !important;
}

.u-w-40 {
  width: 2.5rem !important;
}

.u-w-45 {
  width: 2.8125rem !important;
}

.u-w-50 {
  width: 3.125rem !important;
}

.u-w-55 {
  width: 3.4375rem !important;
}

.u-w-60 {
  width: 3.75rem !important;
}

.u-w-65 {
  width: 4.0625rem !important;
}

.u-w-70 {
  width: 4.375rem !important;
}

.u-w-75 {
  width: 4.6875rem !important;
}

.u-w-80 {
  width: 5rem !important;
}

.u-w-85 {
  width: 5.3125rem !important;
}

.u-w-90 {
  width: 5.625rem !important;
}

.u-w-95 {
  width: 5.9375rem !important;
}

.u-w-100 {
  width: 6.25rem !important;
}

.u-w-100 {
  width: 6.25rem !important;
}

.u-w-110 {
  width: 6.875rem !important;
}

.u-w-120 {
  width: 7.5rem !important;
}

.u-w-130 {
  width: 8.125rem !important;
}

.u-w-140 {
  width: 8.75rem !important;
}

.u-w-150 {
  width: 9.375rem !important;
}

.u-w-160 {
  width: 10rem !important;
}

.u-w-170 {
  width: 10.625rem !important;
}

.u-w-180 {
  width: 11.25rem !important;
}

.u-w-190 {
  width: 11.875rem !important;
}

.u-w-200 {
  width: 12.5rem !important;
}

.u-w-210 {
  width: 13.125rem !important;
}

.u-w-220 {
  width: 13.75rem !important;
}

.u-w-230 {
  width: 14.375rem !important;
}

.u-w-240 {
  width: 15rem !important;
}

.u-w-250 {
  width: 15.625rem !important;
}

.u-w-260 {
  width: 16.25rem !important;
}

.u-w-270 {
  width: 16.875rem !important;
}

.u-w-280 {
  width: 17.5rem !important;
}

.u-w-290 {
  width: 18.125rem !important;
}

.u-w-300 {
  width: 18.75rem !important;
}

.u-max-w-300 {
  width: 18.75rem !important;
}

.u-max-w-350 {
  width: 21.875rem !important;
}

.u-max-w-400 {
  width: 25rem !important;
}

.u-max-w-450 {
  width: 28.125rem !important;
}

.u-max-w-500 {
  width: 31.25rem !important;
}

.u-max-w-550 {
  width: 34.375rem !important;
}

.u-max-w-600 {
  width: 37.5rem !important;
}

.u-max-w-650 {
  width: 40.625rem !important;
}

.u-max-w-700 {
  width: 43.75rem !important;
}

.u-max-w-750 {
  width: 46.875rem !important;
}

.u-max-w-800 {
  width: 50rem !important;
}

.u-max-w-850 {
  width: 53.125rem !important;
}

.u-max-w-900 {
  width: 56.25rem !important;
}

.u-h-30vh {
  min-height: 30vh !important;
}

.u-h-40vh {
  min-height: 40vh !important;
}

.u-h-50vh {
  min-height: 50vh !important;
}

.u-h-60vh {
  min-height: 60vh !important;
}

.u-h-70vh {
  min-height: 70vh !important;
}

.u-h-80vh {
  min-height: 80vh !important;
}

.u-h-90vh {
  min-height: 90vh !important;
}

.u-h-100vh {
  min-height: 100vh !important;
}

.u-h-100p {
  height: 100% !important;
}

.u-h-auto {
  height: auto !important;
}

.u-h-2 {
  height: 0.125rem;
}

.u-h-4 {
  height: 0.25rem;
}

.u-h-6 {
  height: 0.375rem;
}

.u-h-8 {
  height: 0.5rem;
}

.u-h-10 {
  height: 0.625rem;
}

.u-h-12 {
  height: 0.75rem;
}

.u-h-14 {
  height: 0.875rem;
}

.u-h-16 {
  height: 1rem;
}

.u-h-18 {
  height: 1.125rem;
}

.u-h-20 {
  height: 1.25rem;
}

.u-h-5 {
  height: 0.3125rem;
}

.u-h-10 {
  height: 0.625rem;
}

.u-h-15 {
  height: 0.9375rem;
}

.u-h-20 {
  height: 1.25rem;
}

.u-h-25 {
  height: 1.5625rem;
}

.u-h-30 {
  height: 1.875rem;
}

.u-h-35 {
  height: 2.1875rem;
}

.u-h-40 {
  height: 2.5rem;
}

.u-h-45 {
  height: 2.8125rem;
}

.u-h-50 {
  height: 3.125rem;
}

.u-h-55 {
  height: 3.4375rem;
}

.u-h-60 {
  height: 3.75rem;
}

.u-h-65 {
  height: 4.0625rem;
}

.u-h-70 {
  height: 4.375rem;
}

.u-h-75 {
  height: 4.6875rem;
}

.u-h-80 {
  height: 5rem;
}

.u-h-85 {
  height: 5.3125rem;
}

.u-h-90 {
  height: 5.625rem;
}

.u-h-95 {
  height: 5.9375rem;
}

.u-h-100 {
  height: 6.25rem;
}

.u-h-100 {
  height: 6.25rem;
}

.u-h-110 {
  height: 6.875rem;
}

.u-h-120 {
  height: 7.5rem;
}

.u-h-130 {
  height: 8.125rem;
}

.u-h-140 {
  height: 8.75rem;
}

.u-h-150 {
  height: 9.375rem;
}

.u-h-160 {
  height: 10rem;
}

.u-h-170 {
  height: 10.625rem;
}

.u-h-180 {
  height: 11.25rem;
}

.u-h-190 {
  height: 11.875rem;
}

.u-h-200 {
  height: 12.5rem;
}

.u-h-210 {
  height: 13.125rem;
}

.u-h-220 {
  height: 13.75rem;
}

.u-h-230 {
  height: 14.375rem;
}

.u-h-240 {
  height: 15rem;
}

.u-h-250 {
  height: 15.625rem;
}

.u-h-260 {
  height: 16.25rem;
}

.u-h-270 {
  height: 16.875rem;
}

.u-h-280 {
  height: 17.5rem;
}

.u-h-290 {
  height: 18.125rem;
}

.u-h-300 {
  height: 18.75rem;
}

.u-fw-100 {
  font-weight: 100;
}

.u-fw-200 {
  font-weight: 200;
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700 !important;
}

.u-fw-800 {
  font-weight: 800;
}

.u-fw-900 {
  font-weight: 900;
}

@media (min-width: 768px) {
  fw-md-700 {
    font-weight: 700;
  }
  fw-md-800 {
    font-weight: 800;
  }
  fw-md-900 {
    font-weight: 900;
  }
}

@media (min-width: 992px) {
  fw-lg-700 {
    font-weight: 700;
  }
  fw-lg-800 {
    font-weight: 800;
  }
  fw-lg-900 {
    font-weight: 900;
  }
}

.u-p-5 {
  padding: 0.3125rem !important;
}

.u-p-6 {
  padding: 0.375rem !important;
}

.u-p-7 {
  padding: 0.4375rem !important;
}

.u-p-8 {
  padding: 0.5rem !important;
}

.u-p-9 {
  padding: 0.5625rem !important;
}

.u-p-10 {
  padding: 0.625rem !important;
}

.u-p-11 {
  padding: 0.6875rem !important;
}

.u-p-12 {
  padding: 0.75rem !important;
}

.u-p-13 {
  padding: 0.8125rem !important;
}

.u-p-14 {
  padding: 0.875rem !important;
}

.u-p-15 {
  padding: 0.9375rem !important;
}

.u-p-16 {
  padding: 1rem !important;
}

.u-p-17 {
  padding: 1.0625rem !important;
}

.u-p-18 {
  padding: 1.125rem !important;
}

.u-p-19 {
  padding: 1.1875rem !important;
}

.u-p-20 {
  padding: 1.25rem !important;
}

.u-p-20 {
  padding: 1.25rem !important;
}

.u-p-25 {
  padding: 1.5625rem !important;
}

.u-p-30 {
  padding: 1.875rem !important;
}

.u-p-35 {
  padding: 2.1875rem !important;
}

.u-p-40 {
  padding: 2.5rem !important;
}

.u-p-45 {
  padding: 2.8125rem !important;
}

.u-p-50 {
  padding: 3.125rem !important;
}

.u-p-55 {
  padding: 3.4375rem !important;
}

.u-p-60 {
  padding: 3.75rem !important;
}

.u-pt-5 {
  padding-top: 0.3125rem !important;
}

.u-pt-6 {
  padding-top: 0.375rem !important;
}

.u-pt-7 {
  padding-top: 0.4375rem !important;
}

.u-pt-8 {
  padding-top: 0.5rem !important;
}

.u-pt-9 {
  padding-top: 0.5625rem !important;
}

.u-pt-10 {
  padding-top: 0.625rem !important;
}

.u-pt-11 {
  padding-top: 0.6875rem !important;
}

.u-pt-12 {
  padding-top: 0.75rem !important;
}

.u-pt-13 {
  padding-top: 0.8125rem !important;
}

.u-pt-14 {
  padding-top: 0.875rem !important;
}

.u-pt-15 {
  padding-top: 0.9375rem !important;
}

.u-pt-16 {
  padding-top: 1rem !important;
}

.u-pt-17 {
  padding-top: 1.0625rem !important;
}

.u-pt-18 {
  padding-top: 1.125rem !important;
}

.u-pt-19 {
  padding-top: 1.1875rem !important;
}

.u-pt-20 {
  padding-top: 1.25rem !important;
}

.u-pt-20 {
  padding-top: 1.25rem !important;
}

.u-pt-25 {
  padding-top: 1.5625rem !important;
}

.u-pt-30 {
  padding-top: 1.875rem !important;
}

.u-pt-35 {
  padding-top: 2.1875rem !important;
}

.u-pt-40 {
  padding-top: 2.5rem !important;
}

.u-pt-45 {
  padding-top: 2.8125rem !important;
}

.u-pt-50 {
  padding-top: 3.125rem !important;
}

.u-pt-55 {
  padding-top: 3.4375rem !important;
}

.u-pt-60 {
  padding-top: 3.75rem !important;
}

.u-pt-65 {
  padding-top: 4.0625rem !important;
}

.u-pt-70 {
  padding-top: 4.375rem !important;
}

.u-pt-75 {
  padding-top: 4.6875rem !important;
}

.u-pt-80 {
  padding-top: 5rem !important;
}

.u-pt-85 {
  padding-top: 5.3125rem !important;
}

.u-pt-90 {
  padding-top: 5.625rem !important;
}

.u-pt-95 {
  padding-top: 5.9375rem !important;
}

.u-pt-100 {
  padding-top: 6.25rem !important;
}

.u-pb-5 {
  padding-bottom: 0.3125rem !important;
}

.u-pb-6 {
  padding-bottom: 0.375rem !important;
}

.u-pb-7 {
  padding-bottom: 0.4375rem !important;
}

.u-pb-8 {
  padding-bottom: 0.5rem !important;
}

.u-pb-9 {
  padding-bottom: 0.5625rem !important;
}

.u-pb-10 {
  padding-bottom: 0.625rem !important;
}

.u-pb-11 {
  padding-bottom: 0.6875rem !important;
}

.u-pb-12 {
  padding-bottom: 0.75rem !important;
}

.u-pb-13 {
  padding-bottom: 0.8125rem !important;
}

.u-pb-14 {
  padding-bottom: 0.875rem !important;
}

.u-pb-15 {
  padding-bottom: 0.9375rem !important;
}

.u-pb-16 {
  padding-bottom: 1rem !important;
}

.u-pb-17 {
  padding-bottom: 1.0625rem !important;
}

.u-pb-18 {
  padding-bottom: 1.125rem !important;
}

.u-pb-19 {
  padding-bottom: 1.1875rem !important;
}

.u-pb-20 {
  padding-bottom: 1.25rem !important;
}

.u-pb-25 {
  padding-bottom: 1.5625rem !important;
}

.u-pb-30 {
  padding-bottom: 1.875rem !important;
}

.u-pb-35 {
  padding-bottom: 2.1875rem !important;
}

.u-pb-40 {
  padding-bottom: 2.5rem !important;
}

.u-pb-45 {
  padding-bottom: 2.8125rem !important;
}

.u-pb-50 {
  padding-bottom: 3.125rem !important;
}

.u-pb-55 {
  padding-bottom: 3.4375rem !important;
}

.u-pb-60 {
  padding-bottom: 3.75rem !important;
}

.u-pb-65 {
  padding-bottom: 4.0625rem !important;
}

.u-pb-70 {
  padding-bottom: 4.375rem !important;
}

.u-pb-75 {
  padding-bottom: 4.6875rem !important;
}

.u-pb-80 {
  padding-bottom: 5rem !important;
}

.u-pb-85 {
  padding-bottom: 5.3125rem !important;
}

.u-pb-90 {
  padding-bottom: 5.625rem !important;
}

.u-pb-95 {
  padding-bottom: 5.9375rem !important;
}

.u-pb-100 {
  padding-bottom: 6.25rem !important;
}

.u-pl-5 {
  padding-left: 0.3125rem !important;
}

.u-pl-6 {
  padding-left: 0.375rem !important;
}

.u-pl-7 {
  padding-left: 0.4375rem !important;
}

.u-pl-8 {
  padding-left: 0.5rem !important;
}

.u-pl-9 {
  padding-left: 0.5625rem !important;
}

.u-pl-10 {
  padding-left: 0.625rem !important;
}

.u-pl-11 {
  padding-left: 0.6875rem !important;
}

.u-pl-12 {
  padding-left: 0.75rem !important;
}

.u-pl-13 {
  padding-left: 0.8125rem !important;
}

.u-pl-14 {
  padding-left: 0.875rem !important;
}

.u-pl-15 {
  padding-left: 0.9375rem !important;
}

.u-pl-16 {
  padding-left: 1rem !important;
}

.u-pl-17 {
  padding-left: 1.0625rem !important;
}

.u-pl-18 {
  padding-left: 1.125rem !important;
}

.u-pl-19 {
  padding-left: 1.1875rem !important;
}

.u-pl-20 {
  padding-left: 1.25rem !important;
}

.u-pl-20 {
  padding-left: 1.25rem !important;
}

.u-pl-25 {
  padding-left: 1.5625rem !important;
}

.u-pl-30 {
  padding-left: 1.875rem !important;
}

.u-pl-35 {
  padding-left: 2.1875rem !important;
}

.u-pl-40 {
  padding-left: 2.5rem !important;
}

.u-pl-45 {
  padding-left: 2.8125rem !important;
}

.u-pl-50 {
  padding-left: 3.125rem !important;
}

.u-pl-55 {
  padding-left: 3.4375rem !important;
}

.u-pl-60 {
  padding-left: 3.75rem !important;
}

.u-pl-65 {
  padding-left: 4.0625rem !important;
}

.u-pl-70 {
  padding-left: 4.375rem !important;
}

.u-pl-75 {
  padding-left: 4.6875rem !important;
}

.u-pl-80 {
  padding-left: 5rem !important;
}

.u-pl-85 {
  padding-left: 5.3125rem !important;
}

.u-pl-90 {
  padding-left: 5.625rem !important;
}

.u-pl-95 {
  padding-left: 5.9375rem !important;
}

.u-pl-100 {
  padding-left: 6.25rem !important;
}

.u-pr-5 {
  padding-right: 0.3125rem !important;
}

.u-pr-6 {
  padding-right: 0.375rem !important;
}

.u-pr-7 {
  padding-right: 0.4375rem !important;
}

.u-pr-8 {
  padding-right: 0.5rem !important;
}

.u-pr-9 {
  padding-right: 0.5625rem !important;
}

.u-pr-10 {
  padding-right: 0.625rem !important;
}

.u-pr-11 {
  padding-right: 0.6875rem !important;
}

.u-pr-12 {
  padding-right: 0.75rem !important;
}

.u-pr-13 {
  padding-right: 0.8125rem !important;
}

.u-pr-14 {
  padding-right: 0.875rem !important;
}

.u-pr-15 {
  padding-right: 0.9375rem !important;
}

.u-pr-16 {
  padding-right: 1rem !important;
}

.u-pr-17 {
  padding-right: 1.0625rem !important;
}

.u-pr-18 {
  padding-right: 1.125rem !important;
}

.u-pr-19 {
  padding-right: 1.1875rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-25 {
  padding-right: 1.5625rem !important;
}

.u-pr-30 {
  padding-right: 1.875rem !important;
}

.u-pr-35 {
  padding-right: 2.1875rem !important;
}

.u-pr-40 {
  padding-right: 2.5rem !important;
}

.u-pr-45 {
  padding-right: 2.8125rem !important;
}

.u-pr-50 {
  padding-right: 3.125rem !important;
}

.u-pr-55 {
  padding-right: 3.4375rem !important;
}

.u-pr-60 {
  padding-right: 3.75rem !important;
}

.u-pr-65 {
  padding-right: 4.0625rem !important;
}

.u-pr-70 {
  padding-right: 4.375rem !important;
}

.u-pr-75 {
  padding-right: 4.6875rem !important;
}

.u-pr-80 {
  padding-right: 5rem !important;
}

.u-pr-85 {
  padding-right: 5.3125rem !important;
}

.u-pr-90 {
  padding-right: 5.625rem !important;
}

.u-pr-95 {
  padding-right: 5.9375rem !important;
}

.u-pr-100 {
  padding-right: 6.25rem !important;
}

.u-py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.u-py-5 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.u-py-6 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.u-py-7 {
  padding-top: 0.4375rem !important;
  padding-bottom: 0.4375rem !important;
}

.u-py-8 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.u-py-9 {
  padding-top: 0.5625rem !important;
  padding-bottom: 0.5625rem !important;
}

.u-py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.u-py-11 {
  padding-top: 0.6875rem !important;
  padding-bottom: 0.6875rem !important;
}

.u-py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.u-py-13 {
  padding-top: 0.8125rem !important;
  padding-bottom: 0.8125rem !important;
}

.u-py-14 {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.u-py-15 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.u-py-16 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.u-py-17 {
  padding-top: 1.0625rem !important;
  padding-bottom: 1.0625rem !important;
}

.u-py-18 {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}

.u-py-19 {
  padding-top: 1.1875rem !important;
  padding-bottom: 1.1875rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-25 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.u-py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.u-py-35 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.u-py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.u-py-45 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.u-py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.u-py-55 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.u-py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.u-py-65 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.u-py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.u-py-75 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important;
}

.u-py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.u-py-85 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important;
}

.u-py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.u-py-95 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important;
}

.u-py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.u-px-5 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.u-px-6 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.u-px-7 {
  padding-left: 0.4375rem !important;
  padding-right: 0.4375rem !important;
}

.u-px-8 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.u-px-9 {
  padding-left: 0.5625rem !important;
  padding-right: 0.5625rem !important;
}

.u-px-10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.u-px-11 {
  padding-left: 0.6875rem !important;
  padding-right: 0.6875rem !important;
}

.u-px-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.u-px-13 {
  padding-left: 0.8125rem !important;
  padding-right: 0.8125rem !important;
}

.u-px-14 {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.u-px-15 {
  padding-left: 0.9375rem !important;
  padding-right: 0.9375rem !important;
}

.u-px-16 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.u-px-17 {
  padding-left: 1.0625rem !important;
  padding-right: 1.0625rem !important;
}

.u-px-18 {
  padding-left: 1.125rem !important;
  padding-right: 1.125rem !important;
}

.u-px-19 {
  padding-left: 1.1875rem !important;
  padding-right: 1.1875rem !important;
}

.u-px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.u-px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.u-px-25 {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;
}

.u-px-30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.u-px-35 {
  padding-left: 2.1875rem !important;
  padding-right: 2.1875rem !important;
}

.u-px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.u-px-45 {
  padding-left: 2.8125rem !important;
  padding-right: 2.8125rem !important;
}

.u-px-50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.u-px-55 {
  padding-left: 3.4375rem !important;
  padding-right: 3.4375rem !important;
}

.u-px-60 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-25 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.u-py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.u-py-35 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.u-py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.u-py-45 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.u-py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.u-py-55 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.u-py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.u-py-65 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.u-py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.u-py-75 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important;
}

.u-py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.u-py-85 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important;
}

.u-py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.u-py-95 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important;
}

.u-py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

@media (min-width: 768px) {
  .u-p-md-60 {
    padding: 3.75rem !important;
  }
  .u-p-md-65 {
    padding: 4.0625rem !important;
  }
  .u-p-md-70 {
    padding: 4.375rem !important;
  }
  .u-p-md-75 {
    padding: 4.6875rem !important;
  }
  .u-p-md-80 {
    padding: 5rem !important;
  }
  .u-p-md-85 {
    padding: 5.3125rem !important;
  }
  .u-p-md-90 {
    padding: 5.625rem !important;
  }
  .u-p-md-95 {
    padding: 5.9375rem !important;
  }
  .u-p-md-100 {
    padding: 6.25rem !important;
  }
  .u-p-md-105 {
    padding: 6.5625rem !important;
  }
  .u-p-md-110 {
    padding: 6.875rem !important;
  }
  .u-p-md-115 {
    padding: 7.1875rem !important;
  }
  .u-p-md-120 {
    padding: 7.5rem !important;
  }
  .u-p-md-125 {
    padding: 7.8125rem !important;
  }
  .u-p-md-130 {
    padding: 8.125rem !important;
  }
  .u-p-md-135 {
    padding: 8.4375rem !important;
  }
  .u-p-md-140 {
    padding: 8.75rem !important;
  }
  .u-p-md-145 {
    padding: 9.0625rem !important;
  }
  .u-p-md-150 {
    padding: 9.375rem !important;
  }
  .u-pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .u-pt-md-65 {
    padding-top: 4.0625rem !important;
  }
  .u-pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .u-pt-md-75 {
    padding-top: 4.6875rem !important;
  }
  .u-pt-md-80 {
    padding-top: 5rem !important;
  }
  .u-pt-md-85 {
    padding-top: 5.3125rem !important;
  }
  .u-pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .u-pt-md-95 {
    padding-top: 5.9375rem !important;
  }
  .u-pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .u-pt-md-105 {
    padding-top: 6.5625rem !important;
  }
  .u-pt-md-110 {
    padding-top: 6.875rem !important;
  }
  .u-pt-md-115 {
    padding-top: 7.1875rem !important;
  }
  .u-pt-md-120 {
    padding-top: 7.5rem !important;
  }
  .u-pt-md-125 {
    padding-top: 7.8125rem !important;
  }
  .u-pt-md-130 {
    padding-top: 8.125rem !important;
  }
  .u-pt-md-135 {
    padding-top: 8.4375rem !important;
  }
  .u-pt-md-140 {
    padding-top: 8.75rem !important;
  }
  .u-pt-md-145 {
    padding-top: 9.0625rem !important;
  }
  .u-pt-md-150 {
    padding-top: 9.375rem !important;
  }
  .u-pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .u-pb-md-65 {
    padding-bottom: 4.0625rem !important;
  }
  .u-pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .u-pb-md-75 {
    padding-bottom: 4.6875rem !important;
  }
  .u-pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .u-pb-md-85 {
    padding-bottom: 5.3125rem !important;
  }
  .u-pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .u-pb-md-95 {
    padding-bottom: 5.9375rem !important;
  }
  .u-pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .u-pb-md-105 {
    padding-bottom: 6.5625rem !important;
  }
  .u-pb-md-110 {
    padding-bottom: 6.875rem !important;
  }
  .u-pb-md-115 {
    padding-bottom: 7.1875rem !important;
  }
  .u-pb-md-120 {
    padding-bottom: 7.5rem !important;
  }
  .u-pb-md-125 {
    padding-bottom: 7.8125rem !important;
  }
  .u-pb-md-130 {
    padding-bottom: 8.125rem !important;
  }
  .u-pb-md-135 {
    padding-bottom: 8.4375rem !important;
  }
  .u-pb-md-140 {
    padding-bottom: 8.75rem !important;
  }
  .u-pb-md-145 {
    padding-bottom: 9.0625rem !important;
  }
  .u-pb-md-150 {
    padding-bottom: 9.375rem !important;
  }
  .u-pl-md-60 {
    padding-left: 3.75rem !important;
  }
  .u-pl-md-65 {
    padding-left: 4.0625rem !important;
  }
  .u-pl-md-70 {
    padding-left: 4.375rem !important;
  }
  .u-pl-md-75 {
    padding-left: 4.6875rem !important;
  }
  .u-pl-md-80 {
    padding-left: 5rem !important;
  }
  .u-pl-md-85 {
    padding-left: 5.3125rem !important;
  }
  .u-pl-md-90 {
    padding-left: 5.625rem !important;
  }
  .u-pl-md-95 {
    padding-left: 5.9375rem !important;
  }
  .u-pl-md-100 {
    padding-left: 6.25rem !important;
  }
  .u-pl-md-105 {
    padding-left: 6.5625rem !important;
  }
  .u-pl-md-110 {
    padding-left: 6.875rem !important;
  }
  .u-pl-md-115 {
    padding-left: 7.1875rem !important;
  }
  .u-pl-md-120 {
    padding-left: 7.5rem !important;
  }
  .u-pl-md-125 {
    padding-left: 7.8125rem !important;
  }
  .u-pl-md-130 {
    padding-left: 8.125rem !important;
  }
  .u-pl-md-135 {
    padding-left: 8.4375rem !important;
  }
  .u-pl-md-140 {
    padding-left: 8.75rem !important;
  }
  .u-pl-md-145 {
    padding-left: 9.0625rem !important;
  }
  .u-pl-md-150 {
    padding-left: 9.375rem !important;
  }
  .u-pr-md-60 {
    padding-right: 3.75rem !important;
  }
  .u-pr-md-65 {
    padding-right: 4.0625rem !important;
  }
  .u-pr-md-70 {
    padding-right: 4.375rem !important;
  }
  .u-pr-md-75 {
    padding-right: 4.6875rem !important;
  }
  .u-pr-md-80 {
    padding-right: 5rem !important;
  }
  .u-pr-md-85 {
    padding-right: 5.3125rem !important;
  }
  .u-pr-md-90 {
    padding-right: 5.625rem !important;
  }
  .u-pr-md-95 {
    padding-right: 5.9375rem !important;
  }
  .u-pr-md-100 {
    padding-right: 6.25rem !important;
  }
  .u-pr-md-105 {
    padding-right: 6.5625rem !important;
  }
  .u-pr-md-110 {
    padding-right: 6.875rem !important;
  }
  .u-pr-md-115 {
    padding-right: 7.1875rem !important;
  }
  .u-pr-md-120 {
    padding-right: 7.5rem !important;
  }
  .u-pr-md-125 {
    padding-right: 7.8125rem !important;
  }
  .u-pr-md-130 {
    padding-right: 8.125rem !important;
  }
  .u-pr-md-135 {
    padding-right: 8.4375rem !important;
  }
  .u-pr-md-140 {
    padding-right: 8.75rem !important;
  }
  .u-pr-md-145 {
    padding-right: 9.0625rem !important;
  }
  .u-pr-md-150 {
    padding-right: 9.375rem !important;
  }
  .u-py-md-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .u-py-md-65 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .u-py-md-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .u-py-md-75 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important;
  }
  .u-py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .u-py-md-85 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important;
  }
  .u-py-md-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .u-py-md-95 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important;
  }
  .u-py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .u-py-md-105 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important;
  }
  .u-py-md-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .u-py-md-115 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important;
  }
  .u-py-md-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .u-py-md-125 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important;
  }
  .u-py-md-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .u-py-md-135 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important;
  }
  .u-py-md-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .u-py-md-145 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important;
  }
  .u-py-md-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .u-px-md-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .u-px-md-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .u-px-md-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .u-px-md-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .u-px-md-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .u-px-md-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .u-px-md-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .u-px-md-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .u-px-md-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .u-px-md-105 {
    padding-left: 6.5625rem !important;
    padding-right: 6.5625rem !important;
  }
  .u-px-md-110 {
    padding-left: 6.875rem !important;
    padding-right: 6.875rem !important;
  }
  .u-px-md-115 {
    padding-left: 7.1875rem !important;
    padding-right: 7.1875rem !important;
  }
  .u-px-md-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .u-px-md-125 {
    padding-left: 7.8125rem !important;
    padding-right: 7.8125rem !important;
  }
  .u-px-md-130 {
    padding-left: 8.125rem !important;
    padding-right: 8.125rem !important;
  }
  .u-px-md-135 {
    padding-left: 8.4375rem !important;
    padding-right: 8.4375rem !important;
  }
  .u-px-md-140 {
    padding-left: 8.75rem !important;
    padding-right: 8.75rem !important;
  }
  .u-px-md-145 {
    padding-left: 9.0625rem !important;
    padding-right: 9.0625rem !important;
  }
  .u-px-md-150 {
    padding-left: 9.375rem !important;
    padding-right: 9.375rem !important;
  }
}

@media (min-width: 992px) {
  .u-p-lg-100 {
    padding: 6.25rem !important;
  }
  .u-p-lg-110 {
    padding: 6.875rem !important;
  }
  .u-p-lg-120 {
    padding: 7.5rem !important;
  }
  .u-p-lg-130 {
    padding: 8.125rem !important;
  }
  .u-p-lg-140 {
    padding: 8.75rem !important;
  }
  .u-p-lg-150 {
    padding: 9.375rem !important;
  }
  .u-p-lg-160 {
    padding: 10rem !important;
  }
  .u-p-lg-170 {
    padding: 10.625rem !important;
  }
  .u-p-lg-180 {
    padding: 11.25rem !important;
  }
  .u-p-lg-190 {
    padding: 11.875rem !important;
  }
  .u-p-lg-200 {
    padding: 12.5rem !important;
  }
  .u-p-lg-210 {
    padding: 13.125rem !important;
  }
  .u-p-lg-220 {
    padding: 13.75rem !important;
  }
  .u-p-lg-230 {
    padding: 14.375rem !important;
  }
  .u-p-lg-240 {
    padding: 15rem !important;
  }
  .u-p-lg-250 {
    padding: 15.625rem !important;
  }
  .u-pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .u-pt-lg-110 {
    padding-top: 6.875rem !important;
  }
  .u-pt-lg-120 {
    padding-top: 7.5rem !important;
  }
  .u-pt-lg-130 {
    padding-top: 8.125rem !important;
  }
  .u-pt-lg-140 {
    padding-top: 8.75rem !important;
  }
  .u-pt-lg-150 {
    padding-top: 9.375rem !important;
  }
  .u-pt-lg-160 {
    padding-top: 10rem !important;
  }
  .u-pt-lg-170 {
    padding-top: 10.625rem !important;
  }
  .u-pt-lg-180 {
    padding-top: 11.25rem !important;
  }
  .u-pt-lg-190 {
    padding-top: 11.875rem !important;
  }
  .u-pt-lg-200 {
    padding-top: 12.5rem !important;
  }
  .u-pt-lg-210 {
    padding-top: 13.125rem !important;
  }
  .u-pt-lg-220 {
    padding-top: 13.75rem !important;
  }
  .u-pt-lg-230 {
    padding-top: 14.375rem !important;
  }
  .u-pt-lg-240 {
    padding-top: 15rem !important;
  }
  .u-pt-lg-250 {
    padding-top: 15.625rem !important;
  }
  .u-pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .u-pb-lg-110 {
    padding-bottom: 6.875rem !important;
  }
  .u-pb-lg-120 {
    padding-bottom: 7.5rem !important;
  }
  .u-pb-lg-130 {
    padding-bottom: 8.125rem !important;
  }
  .u-pb-lg-140 {
    padding-bottom: 8.75rem !important;
  }
  .u-pb-lg-150 {
    padding-bottom: 9.375rem !important;
  }
  .u-pb-lg-160 {
    padding-bottom: 10rem !important;
  }
  .u-pb-lg-170 {
    padding-bottom: 10.625rem !important;
  }
  .u-pb-lg-180 {
    padding-bottom: 11.25rem !important;
  }
  .u-pb-lg-190 {
    padding-bottom: 11.875rem !important;
  }
  .u-pb-lg-200 {
    padding-bottom: 12.5rem !important;
  }
  .u-pb-lg-210 {
    padding-bottom: 13.125rem !important;
  }
  .u-pb-lg-220 {
    padding-bottom: 13.75rem !important;
  }
  .u-pb-lg-230 {
    padding-bottom: 14.375rem !important;
  }
  .u-pb-lg-240 {
    padding-bottom: 15rem !important;
  }
  .u-pb-lg-250 {
    padding-bottom: 15.625rem !important;
  }
  .u-pl-lg-100 {
    padding-left: 6.25rem !important;
  }
  .u-pl-lg-110 {
    padding-left: 6.875rem !important;
  }
  .u-pl-lg-120 {
    padding-left: 7.5rem !important;
  }
  .u-pl-lg-130 {
    padding-left: 8.125rem !important;
  }
  .u-pl-lg-140 {
    padding-left: 8.75rem !important;
  }
  .u-pl-lg-150 {
    padding-left: 9.375rem !important;
  }
  .u-pl-lg-160 {
    padding-left: 10rem !important;
  }
  .u-pl-lg-170 {
    padding-left: 10.625rem !important;
  }
  .u-pl-lg-180 {
    padding-left: 11.25rem !important;
  }
  .u-pl-lg-190 {
    padding-left: 11.875rem !important;
  }
  .u-pl-lg-200 {
    padding-left: 12.5rem !important;
  }
  .u-pl-lg-210 {
    padding-left: 13.125rem !important;
  }
  .u-pl-lg-220 {
    padding-left: 13.75rem !important;
  }
  .u-pl-lg-230 {
    padding-left: 14.375rem !important;
  }
  .u-pl-lg-240 {
    padding-left: 15rem !important;
  }
  .u-pl-lg-250 {
    padding-left: 15.625rem !important;
  }
  .u-pr-lg-100 {
    padding-right: 6.25rem !important;
  }
  .u-pr-lg-110 {
    padding-right: 6.875rem !important;
  }
  .u-pr-lg-120 {
    padding-right: 7.5rem !important;
  }
  .u-pr-lg-130 {
    padding-right: 8.125rem !important;
  }
  .u-pr-lg-140 {
    padding-right: 8.75rem !important;
  }
  .u-pr-lg-150 {
    padding-right: 9.375rem !important;
  }
  .u-pr-lg-160 {
    padding-right: 10rem !important;
  }
  .u-pr-lg-170 {
    padding-right: 10.625rem !important;
  }
  .u-pr-lg-180 {
    padding-right: 11.25rem !important;
  }
  .u-pr-lg-190 {
    padding-right: 11.875rem !important;
  }
  .u-pr-lg-200 {
    padding-right: 12.5rem !important;
  }
  .u-pr-lg-210 {
    padding-right: 13.125rem !important;
  }
  .u-pr-lg-220 {
    padding-right: 13.75rem !important;
  }
  .u-pr-lg-230 {
    padding-right: 14.375rem !important;
  }
  .u-pr-lg-240 {
    padding-right: 15rem !important;
  }
  .u-pr-lg-250 {
    padding-right: 15.625rem !important;
  }
  .u-py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .u-py-lg-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .u-py-lg-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .u-py-lg-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .u-py-lg-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .u-py-lg-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .u-py-lg-160 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .u-py-lg-170 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important;
  }
  .u-py-lg-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .u-py-lg-190 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important;
  }
  .u-py-lg-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .u-py-lg-210 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important;
  }
  .u-py-lg-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .u-py-lg-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .u-py-lg-240 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .u-py-lg-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .u-px-lg-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .u-px-lg-110 {
    padding-left: 6.875rem !important;
    padding-right: 6.875rem !important;
  }
  .u-px-lg-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .u-px-lg-130 {
    padding-left: 8.125rem !important;
    padding-right: 8.125rem !important;
  }
  .u-px-lg-140 {
    padding-left: 8.75rem !important;
    padding-right: 8.75rem !important;
  }
  .u-px-lg-150 {
    padding-left: 9.375rem !important;
    padding-right: 9.375rem !important;
  }
  .u-px-lg-160 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .u-px-lg-170 {
    padding-left: 10.625rem !important;
    padding-right: 10.625rem !important;
  }
  .u-px-lg-180 {
    padding-left: 11.25rem !important;
    padding-right: 11.25rem !important;
  }
  .u-px-lg-190 {
    padding-left: 11.875rem !important;
    padding-right: 11.875rem !important;
  }
  .u-px-lg-200 {
    padding-left: 12.5rem !important;
    padding-right: 12.5rem !important;
  }
  .u-px-lg-210 {
    padding-left: 13.125rem !important;
    padding-right: 13.125rem !important;
  }
  .u-px-lg-220 {
    padding-left: 13.75rem !important;
    padding-right: 13.75rem !important;
  }
  .u-px-lg-230 {
    padding-left: 14.375rem !important;
    padding-right: 14.375rem !important;
  }
  .u-px-lg-240 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }
  .u-px-lg-250 {
    padding-left: 15.625rem !important;
    padding-right: 15.625rem !important;
  }
}

.u-lh-0 {
  line-height: 0;
}

.u-lh-0_7 {
  line-height: 0.7 !important;
}

.u-lh-0_8 {
  line-height: 0.8 !important;
}

.u-lh-0_9 {
  line-height: 0.9 !important;
}

.u-lh-1 {
  line-height: 1 !important;
}

.u-lh-1_1 {
  line-height: 1.1 !important;
}

.u-lh-1_2 {
  line-height: 1.2 !important;
}

.u-lh-1_3 {
  line-height: 1.3 !important;
}

.u-lh-1_4 {
  line-height: 1.4 !important;
}

.u-lh-1_5 {
  line-height: 1.5 !important;
}

.u-lh-1_57 {
  line-height: 1.57 !important;
}

.u-lh-1_6 {
  line-height: 1.6 !important;
}

.u-lh-1_7 {
  line-height: 1.7 !important;
}

.u-lh-1_8 {
  line-height: 1.8 !important;
}

.u-lh-2 {
  line-height: 2 !important;
}

.u-pos-abs {
  position: absolute;
}

.u-pos-rel {
  position: relative;
}

.u-pos-st {
  position: static;
}

@media (min-width: 768px) {
  .u-pos-md-abs {
    position: absolute;
  }
}

@media (min-width: 992px) {
  .u-pos-lg-abs {
    position: absolute;
  }
}

.u-pos-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-pos-abs-center--x {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.u-pos-abs-center--y {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.u-flex-center {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
}

.u-right-0 {
  right: 0 !important;
}

.u-right-5 {
  right: 0.3125rem !important;
}

.u-right-10 {
  right: 0.625rem !important;
}

.u-right-15 {
  right: 0.9375rem !important;
}

.u-right-20 {
  right: 1.25rem !important;
}

.u-right-25 {
  right: 1.5625rem !important;
}

.u-right-30 {
  right: 1.875rem !important;
}

.u-right-35 {
  right: 2.1875rem !important;
}

.u-right-40 {
  right: 2.5rem !important;
}

.u-right-45 {
  right: 2.8125rem !important;
}

.u-right-50 {
  right: 3.125rem !important;
}

.u-right-minus-5 {
  right: -0.3125rem !important;
}

.u-right-minus-10 {
  right: -0.625rem !important;
}

.u-right-minus-15 {
  right: -0.9375rem !important;
}

.u-right-minus-20 {
  right: -1.25rem !important;
}

.u-right-minus-25 {
  right: -1.5625rem !important;
}

.u-right-minus-30 {
  right: -1.875rem !important;
}

.u-right-minus-35 {
  right: -2.1875rem !important;
}

.u-right-minus-40 {
  right: -2.5rem !important;
}

.u-right-minus-45 {
  right: -2.8125rem !important;
}

.u-right-minus-50 {
  right: -3.125rem !important;
}

.u-right-25p {
  right: 25% !important;
}

.u-right-50p {
  right: 50% !important;
}

.u-right-75p {
  right: 75% !important;
}

.u-right-100p {
  right: 100% !important;
}

.u-left-0 {
  left: 0 !important;
}

.u-left-5 {
  left: 0.3125rem !important;
}

.u-left-10 {
  left: 0.625rem !important;
}

.u-left-15 {
  left: 0.9375rem !important;
}

.u-left-20 {
  left: 1.25rem !important;
}

.u-left-25 {
  left: 1.5625rem !important;
}

.u-left-30 {
  left: 1.875rem !important;
}

.u-left-35 {
  left: 2.1875rem !important;
}

.u-left-40 {
  left: 2.5rem !important;
}

.u-left-45 {
  left: 2.8125rem !important;
}

.u-left-50 {
  left: 3.125rem !important;
}

.u-left-minus-5 {
  left: -0.3125rem !important;
}

.u-left-minus-10 {
  left: -0.625rem !important;
}

.u-left-minus-15 {
  left: -0.9375rem !important;
}

.u-left-minus-20 {
  left: -1.25rem !important;
}

.u-left-minus-25 {
  left: -1.5625rem !important;
}

.u-left-minus-30 {
  left: -1.875rem !important;
}

.u-left-minus-35 {
  left: -2.1875rem !important;
}

.u-left-minus-40 {
  left: -2.5rem !important;
}

.u-left-minus-45 {
  left: -2.8125rem !important;
}

.u-left-minus-50 {
  left: -3.125rem !important;
}

.u-left-25p {
  left: 25% !important;
}

.u-left-50p {
  left: 50% !important;
}

.u-left-75p {
  left: 75% !important;
}

.u-left-100p {
  left: 100% !important;
}

.u-top-0 {
  top: 0 !important;
}

.u-top-5 {
  top: 0.3125rem !important;
}

.u-top-10 {
  top: 0.625rem !important;
}

.u-top-15 {
  top: 0.9375rem !important;
}

.u-top-20 {
  top: 1.25rem !important;
}

.u-top-25 {
  top: 1.5625rem !important;
}

.u-top-30 {
  top: 1.875rem !important;
}

.u-top-35 {
  top: 2.1875rem !important;
}

.u-top-40 {
  top: 2.5rem !important;
}

.u-top-45 {
  top: 2.8125rem !important;
}

.u-top-50 {
  top: 3.125rem !important;
}

.u-top-minus-5 {
  top: -0.3125rem !important;
}

.u-top-minus-10 {
  top: -0.625rem !important;
}

.u-top-minus-15 {
  top: -0.9375rem !important;
}

.u-top-minus-20 {
  top: -1.25rem !important;
}

.u-top-minus-25 {
  top: -1.5625rem !important;
}

.u-top-minus-30 {
  top: -1.875rem !important;
}

.u-top-minus-35 {
  top: -2.1875rem !important;
}

.u-top-minus-40 {
  top: -2.5rem !important;
}

.u-top-minus-45 {
  top: -2.8125rem !important;
}

.u-top-minus-50 {
  top: -3.125rem !important;
}

.u-top-25p {
  top: 25% !important;
}

.u-top-50p {
  top: 50% !important;
}

.u-top-75p {
  top: 75% !important;
}

.u-top-100p {
  top: 100% !important;
}

.u-bottom-0 {
  bottom: 0 !important;
}

.u-bottom-5 {
  bottom: 0.3125rem !important;
}

.u-bottom-10 {
  bottom: 0.625rem !important;
}

.u-bottom-15 {
  bottom: 0.9375rem !important;
}

.u-bottom-20 {
  bottom: 1.25rem !important;
}

.u-bottom-25 {
  bottom: 1.5625rem !important;
}

.u-bottom-30 {
  bottom: 1.875rem !important;
}

.u-bottom-35 {
  bottom: 2.1875rem !important;
}

.u-bottom-40 {
  bottom: 2.5rem !important;
}

.u-bottom-45 {
  bottom: 2.8125rem !important;
}

.u-bottom-50 {
  bottom: 3.125rem !important;
}

.u-bottom-minus-1 {
  bottom: -0.059rem !important;
}
@media (max-width: 768px) {
  .u-bottom-minus-1 {
    bottom: -0.1565rem !important;
  }
}

.u-bottom-minus-5 {
  bottom: -0.3125rem !important;
}

.u-bottom-minus-10 {
  bottom: -0.625rem !important;
}

.u-bottom-minus-15 {
  bottom: -0.9375rem !important;
}

.u-bottom-minus-20 {
  bottom: -1.25rem !important;
}

.u-bottom-minus-25 {
  bottom: -1.5625rem !important;
}

.u-bottom-minus-30 {
  bottom: -1.875rem !important;
}

.u-bottom-minus-35 {
  bottom: -2.1875rem !important;
}

.u-bottom-minus-40 {
  bottom: -2.5rem !important;
}

.u-bottom-minus-45 {
  bottom: -2.8125rem !important;
}

.u-bottom-minus-50 {
  bottom: -3.125rem !important;
}

.u-bottom-25p {
  bottom: 25% !important;
}

.u-bottom-50p {
  bottom: 50% !important;
}

.u-bottom-75p {
  bottom: 75% !important;
}

.u-bottom-100p {
  bottom: 100% !important;
}

.u-z-index-minus-1 {
  z-index: -1;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-5 {
  z-index: 5;
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-20 {
  z-index: 20;
}

.u-z-index-50 {
  z-index: 50;
}

.bg-darkerWhite {
  background-color: #f5f5f5;
}

.bg-darkerWhite_2 {
  background-color: #efefef;
}

.bg-white {
  background: #fff !important;
}
.bg-white-opacity-0_1 {
  background: rgba(255, 255, 255, 0.1);
}
.bg-white-opacity-0_2 {
  background: rgba(255, 255, 255, 0.2);
}
.bg-white-opacity-0_3 {
  background: rgba(255, 255, 255, 0.3);
}
.bg-white-opacity-0_4 {
  background: rgba(255, 255, 255, 0.4);
}
.bg-white-opacity-0_5 {
  background: rgba(255, 255, 255, 0.5);
}
.bg-white-opacity-0_6 {
  background: rgba(255, 255, 255, 0.6);
}
.bg-white-opacity-0_7 {
  background: rgba(255, 255, 255, 0.7);
}
.bg-white-opacity-0_8 {
  background: rgba(255, 255, 255, 0.8);
}
.bg-white-opacity-0_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg-white--onHover:hover {
  background: #fff !important;
  color: #fff;
}

.bg-white-opacity {
  background: rgba(255, 255, 255, 0.75) !important;
}

.bg-black {
  background: #070b0e !important;
}
.bg-black-opacity-0_1 {
  background: rgba(17, 17, 17, 0.1);
}
.bg-black-opacity-0_2 {
  background: rgba(17, 17, 17, 0.2);
}
.bg-black-opacity-0_3 {
  background: rgba(17, 17, 17, 0.3);
}
.bg-black-opacity-0_4 {
  background: rgba(17, 17, 17, 0.4);
}
.bg-black-opacity-0_5 {
  background: rgba(17, 17, 17, 0.5);
}
.bg-black-opacity-0_6 {
  background: rgba(17, 17, 17, 0.6);
}
.bg-black-opacity-0_7 {
  background: rgba(17, 17, 17, 0.7);
}
.bg-black-opacity-0_8 {
  background: rgba(17, 17, 17, 0.8);
}
.bg-black-opacity-0_9 {
  background: rgba(17, 17, 17);
}

.bg-black--onHover:hover {
  background: #111 !important;
  color: #fff;
}

.bg-black-opacity {
  background: rgba(17, 17, 17, 0.75) !important;
}

.bg-blue {
  background: #3564ff !important;
}
.bg-blue-opacity-0_1 {
  background: rgba(0, 129, 255, 0.1);
}
.bg-blue-opacity-0_2 {
  background: rgba(0, 129, 255, 0.2);
}
.bg-blue-opacity-0_3 {
  background: rgba(0, 129, 255, 0.3);
}
.bg-blue-opacity-0_4 {
  background: rgba(0, 129, 255, 0.4);
}
.bg-blue-opacity-0_5 {
  background: rgba(0, 129, 255, 0.5);
}
.bg-blue-opacity-0_6 {
  background: rgba(0, 129, 255, 0.6);
}
.bg-blue-opacity-0_7 {
  background: rgba(0, 129, 255, 0.7);
}
.bg-blue-opacity-0_8 {
  background: rgba(0, 129, 255, 0.8);
}
.bg-blue-opacity-0_9 {
  background: rgba(0, 129, 255, 0.9);
}

.bg-blue--onHover:hover {
  background: #3564ff !important;
  color: #fff;
}

.bg-blue-opacity {
  background: rgba(0, 129, 255, 0.75) !important;
}

.bg-purple {
  background: #8c47e2 !important;
}
.bg-purple-opacity-0_1 {
  background: rgba(140, 71, 226, 0.1);
}
.bg-purple-opacity-0_2 {
  background: rgba(140, 71, 226, 0.2);
}
.bg-purple-opacity-0_3 {
  background: rgba(140, 71, 226, 0.3);
}
.bg-purple-opacity-0_4 {
  background: rgba(140, 71, 226, 0.4);
}
.bg-purple-opacity-0_5 {
  background: rgba(140, 71, 226, 0.5);
}
.bg-purple-opacity-0_6 {
  background: rgba(140, 71, 226, 0.6);
}
.bg-purple-opacity-0_7 {
  background: rgba(140, 71, 226, 0.7);
}
.bg-purple-opacity-0_8 {
  background: rgba(140, 71, 226, 0.8);
}
.bg-purple-opacity-0_9 {
  background: rgba(140, 71, 226, 0.9);
}

.bg-purple--onHover:hover {
  background: #8c47e2 !important;
  color: #fff;
}

.bg-purple-opacity {
  background: rgba(140, 71, 226, 0.75) !important;
}

.bg-purple-light {
  background: #a282e3 !important;
}
.bg-purple-light-opacity-0_1 {
  background: rgba(162, 130, 227, 0.1);
}
.bg-purple-light-opacity-0_2 {
  background: rgba(162, 130, 227, 0.2);
}
.bg-purple-light-opacity-0_3 {
  background: rgba(162, 130, 227, 0.3);
}
.bg-purple-light-opacity-0_4 {
  background: rgba(162, 130, 227, 0.4);
}
.bg-purple-light-opacity-0_5 {
  background: rgba(162, 130, 227, 0.5);
}
.bg-purple-light-opacity-0_6 {
  background: rgba(162, 130, 227, 0.6);
}
.bg-purple-light-opacity-0_7 {
  background: rgba(162, 130, 227, 0.7);
}
.bg-purple-light-opacity-0_8 {
  background: rgba(162, 130, 227, 0.8);
}
.bg-purple-light-opacity-0_9 {
  background: rgba(162, 130, 227, 0.9);
}

.bg-purple-light--onHover:hover {
  background: #a282e3 !important;
  color: #fff;
}

.bg-purple-light-opacity {
  background: rgba(162, 130, 227, 0.75) !important;
}

.bg-yellow {
  background: #fcc851 !important;
}
.bg-yellow-opacity-0_1 {
  background: rgba(255, 173, 0, 0.1);
}
.bg-yellow-opacity-0_2 {
  background: rgba(255, 173, 0, 0.2);
}
.bg-yellow-opacity-0_3 {
  background: rgba(255, 173, 0, 0.3);
}
.bg-yellow-opacity-0_4 {
  background: rgba(255, 173, 0, 0.4);
}
.bg-yellow-opacity-0_5 {
  background: rgba(255, 173, 0, 0.5);
}
.bg-yellow-opacity-0_6 {
  background: rgba(255, 173, 0, 0.6);
}
.bg-yellow-opacity-0_7 {
  background: rgba(255, 173, 0, 0.7);
}
.bg-yellow-opacity-0_8 {
  background: rgba(255, 173, 0, 0.8);
}
.bg-yellow-opacity-0_9 {
  background: rgba(255, 173, 0, 0.9);
}

.bg-yellow--onHover:hover {
  background: #ffad00 !important;
  color: #fff;
}

.bg-yellow-opacity {
  background: rgba(255, 173, 0, 0.75) !important;
}

.bg-light-grey {
  background: #f2f4f8 !important;
}

.bg-green {
  background: #2cd44a !important;
}
.bg-green-opacity-0_1 {
  background: rgba(44, 212, 74, 0.1);
}
.bg-green-opacity-0_2 {
  background: rgba(44, 212, 74, 0.2);
}
.bg-green-opacity-0_3 {
  background: rgba(44, 212, 74, 0.3);
}
.bg-green-opacity-0_4 {
  background: rgba(44, 212, 74, 0.4);
}
.bg-green-opacity-0_5 {
  background: rgba(44, 212, 74, 0.5);
}
.bg-green-opacity-0_6 {
  background: rgba(44, 212, 74, 0.6);
}
.bg-green-opacity-0_7 {
  background: rgba(44, 212, 74, 0.7);
}
.bg-green-opacity-0_8 {
  background: rgba(44, 212, 74, 0.8);
}
.bg-green-opacity-0_9 {
  background: rgba(44, 212, 74, 0.9);
}

.bg-green--onHover:hover {
  background: #2cd44a !important;
  color: #fff;
}

.bg-green-opacity {
  background: rgba(44, 212, 74, 0.75) !important;
}

.bg-red {
  background: #f64d62 !important;
}
.bg-red-opacity-0_1 {
  background: rgba(246, 77, 98, 0.1);
}
.bg-red-opacity-0_2 {
  background: rgba(246, 77, 98, 0.2);
}
.bg-red-opacity-0_3 {
  background: rgba(246, 77, 98, 0.3);
}
.bg-red-opacity-0_4 {
  background: rgba(246, 77, 98, 0.4);
}
.bg-red-opacity-0_5 {
  background: rgba(246, 77, 98, 0.5);
}
.bg-red-opacity-0_6 {
  background: rgba(246, 77, 98, 0.6);
}
.bg-red-opacity-0_7 {
  background: rgba(246, 77, 98, 0.7);
}
.bg-red-opacity-0_8 {
  background: rgba(246, 77, 98, 0.8);
}
.bg-red-opacity-0_9 {
  background: rgba(246, 77, 98, 0.9);
}

.bg-red--onHover:hover {
  background: #f64d62 !important;
  color: #fff;
}

.bg-red-opacity {
  background: rgba(246, 77, 98, 0.75) !important;
}

.bg-indigo {
  background: #3728f6 !important;
}
.bg-indigo-opacity-0_1 {
  background: rgba(93, 81, 246, 0.1);
}
.bg-indigo-opacity-0_2 {
  background: rgba(93, 81, 246, 0.2);
}
.bg-indigo-opacity-0_3 {
  background: rgba(93, 81, 246, 0.3);
}
.bg-indigo-opacity-0_4 {
  background: rgba(93, 81, 246, 0.4);
}
.bg-indigo-opacity-0_5 {
  background: rgba(93, 81, 246, 0.5);
}
.bg-indigo-opacity-0_6 {
  background: rgba(93, 81, 246, 0.6);
}
.bg-indigo-opacity-0_7 {
  background: rgba(93, 81, 246, 0.7);
}
.bg-indigo-opacity-0_8 {
  background: rgba(93, 81, 246, 0.8);
}
.bg-indigo-opacity-0_9 {
  background: rgba(93, 81, 246, 0.9);
}

.bg-indigo--onHover:hover {
  background: #5d51f6 !important;
  color: #fff;
}

.bg-indigo-opacity {
  background: #5d51f6 !important;
}

.bg-lime {
  background: #c8d046 !important;
}
.bg-lime-opacity-0_1 {
  background: rgba(200, 208, 70, 0.1);
}
.bg-lime-opacity-0_2 {
  background: rgba(200, 208, 70, 0.2);
}
.bg-lime-opacity-0_3 {
  background: rgba(200, 208, 70, 0.3);
}
.bg-lime-opacity-0_4 {
  background: rgba(200, 208, 70, 0.4);
}
.bg-lime-opacity-0_5 {
  background: rgba(200, 208, 70, 0.5);
}
.bg-lime-opacity-0_6 {
  background: rgba(200, 208, 70, 0.6);
}
.bg-lime-opacity-0_7 {
  background: rgba(200, 208, 70, 0.7);
}
.bg-lime-opacity-0_8 {
  background: rgba(200, 208, 70, 0.8);
}
.bg-lime-opacity-0_9 {
  background: rgba(200, 208, 70, 0.9);
}

.bg-lime--onHover:hover {
  background: #c8d046 !important;
  color: #fff;
}

.bg-lime-opacity {
  background: rgba(200, 208, 70, 0.75) !important;
}

.bg-primary-opacity-0_1 {
  background: rgba(0, 129, 255, 0.1);
}

.bg-primary-opacity-0_2 {
  background: rgba(0, 129, 255, 0.2);
}

.bg-primary-opacity-0_3 {
  background: rgba(0, 129, 255, 0.3);
}

.bg-primary-opacity-0_4 {
  background: rgba(0, 129, 255, 0.4);
}

.bg-primary-opacity-0_5 {
  background: rgba(0, 129, 255, 0.5);
}

.bg-primary-opacity-0_6 {
  background: rgba(0, 129, 255, 0.6);
}

.bg-primary-opacity-0_7 {
  background: rgba(0, 129, 255, 0.7);
}

.bg-primary-opacity-0_8 {
  background: rgba(0, 129, 255, 0.8);
}

.bg-primary-opacity-0_9 {
  background: rgba(0, 129, 255, 0.9);
}

.bg-facebook {
  background: #3b5998;
}

.bg-twitter {
  background: #46acf9;
}

.bg-google {
  background: #dd4b39;
}

.bg-linkedin {
  background: #007bb6;
}

.bg-youtube {
  background: #bb0000;
}

.bg-instagram {
  background: #517fa4;
}

.bg-pinterest {
  background: #cb2027;
}

.bg-flickr {
  background: #ff0084;
}

.bg-tumblr {
  background: #32506d;
}

.bg-forusquare {
  background: #0072b1;
}

.bg-dribbble {
  background: #ea4c89;
}

.bg-vine {
  background: #00bf8f;
}

.text-white {
  color: #fff !important;
}

.text-dark-black {
  color: #252525 !important;
}

.text-blue {
  color: #3564ff !important;
}

.text-purple {
  color: #8c47e2 !important;
}

.text-purple-light {
  color: #a282e3 !important;
}

.text-yellow {
  color: #fcc851 !important;
}

.text-green {
  color: #22891f !important;
}

.text-red {
  color: #f64d62 !important;
}

.text-indigo {
  color: #5d51f6 !important;
}

.text-lime {
  color: #c8d046 !important;
}

.color-heading {
  color: #273f5b;
}

.bg-primary {
  background: #3564ff !important;
}

.bg-dark {
  background: #20283b !important;
}

.color-primary {
  color: #3564ff !important;
}

.color-main {
  color: #fcc851 !important;
}

.text-muted {
  color: #7b8697 !important;
}

.bg-navy {
  background: #273f5b;
  color: #a5bacf;
}

.bg-navy-dark {
  background: #20344a;
  color: #a5bacf;
}

.text-navy {
  color: #273f5b;
}

.text-navy-dark {
  color: #20344a;
}

@media (min-width: 767px) {
  .bg-two-step:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background: #2c364c;
    top: 0;
  }
}

.purple-gradian {
  background: #8b3ac6;
  background-image: linear-gradient(to right, #8b3ac6 0%, #4d3399 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF8B3AC6', endColorstr='#FF4D3399', GradientType=1);
}

.bg-gray-v1 {
  background: #f3f6fb;
}

.bg-gray-v2 {
  background: #ecf5fe;
}

.bg-gray-v3 {
  background: #f0f3fa;
}

.u-fs-10 {
  font-size: 0.625rem !important;
}

.u-fs-11 {
  font-size: 0.6875rem !important;
}

.u-fs-12 {
  font-size: 0.75rem !important;
}

.u-fs-13 {
  font-size: 0.8125rem !important;
}

.u-fs-14 {
  font-size: 0.875rem !important;
}

.u-fs-15 {
  font-size: 0.9375rem !important;
}

.u-fs-16 {
  font-size: 1rem !important;
}

.u-fs-17 {
  font-size: 1.0625rem !important;
}

.u-fs-18 {
  font-size: 1.125rem !important;
}

.u-fs-19 {
  font-size: 1.1875rem !important;
}

.u-fs-20 {
  font-size: 1.25rem !important;
}

.u-fs-21 {
  font-size: 1.3125rem !important;
}

.u-fs-22 {
  font-size: 1.375rem !important;
}

.u-fs-23 {
  font-size: 1.4375rem !important;
}

.u-fs-24 {
  font-size: 1.5rem !important;
}

.u-fs-25 {
  font-size: 1.5625rem !important;
}

.u-fs-26 {
  font-size: 1.625rem !important;
}

.u-fs-27 {
  font-size: 1.6875rem !important;
}

.u-fs-28 {
  font-size: 1.75rem !important;
}

.u-fs-29 {
  font-size: 1.8125rem !important;
}

.u-fs-30 {
  font-size: 1.875rem !important;
}

.u-fs-30 {
  font-size: 1.875rem !important;
}

.u-fs-32 {
  font-size: 2rem !important;
}

.u-fs-34 {
  font-size: 2.125rem !important;
}

.u-fs-36 {
  font-size: 2.25rem !important;
}

.u-fs-38 {
  font-size: 2.375rem !important;
}

.u-fs-40 {
  font-size: 2.5rem !important;
}

.u-fs-42 {
  font-size: 2.625rem !important;
}

.u-fs-44 {
  font-size: 2.75rem !important;
}

.u-fs-46 {
  font-size: 2.875rem !important;
}

.u-fs-48 {
  font-size: 3rem !important;
}

.u-fs-50 {
  font-size: 3.125rem !important;
}

.u-fs-52 {
  font-size: 3.25rem !important;
}

.u-fs-54 {
  font-size: 3.375rem !important;
}

.u-fs-56 {
  font-size: 3.5rem !important;
}

.u-fs-58 {
  font-size: 3.625rem !important;
}

.u-fs-60 {
  font-size: 3.75rem !important;
}

@media (min-width: 768px) {
  .u-fs-md-150 {
    font-size: 9.375rem !important;
  }
  .u-fs-md-20 {
    font-size: 1.25rem !important;
  }
  .u-fs-md-22 {
    font-size: 1.375rem !important;
  }
  .u-fs-md-24 {
    font-size: 1.5rem !important;
  }
  .u-fs-md-26 {
    font-size: 1.625rem !important;
  }
  .u-fs-md-28 {
    font-size: 1.75rem !important;
  }
  .u-fs-md-30 {
    font-size: 1.875rem !important;
  }
  .u-fs-md-32 {
    font-size: 2rem !important;
  }
  .u-fs-md-34 {
    font-size: 2.125rem !important;
  }
  .u-fs-md-36 {
    font-size: 2.25rem !important;
  }
  .u-fs-md-38 {
    font-size: 2.375rem !important;
  }
  .u-fs-md-40 {
    font-size: 2.5rem !important;
  }
  .u-fs-md-42 {
    font-size: 2.625rem !important;
  }
  .u-fs-md-44 {
    font-size: 2.75rem !important;
  }
  .u-fs-md-46 {
    font-size: 2.875rem !important;
  }
  .u-fs-md-48 {
    font-size: 3rem !important;
  }
  .u-fs-md-50 {
    font-size: 3.125rem !important;
  }
  .u-fs-md-52 {
    font-size: 3.25rem !important;
  }
  .u-fs-md-54 {
    font-size: 3.375rem !important;
  }
  .u-fs-md-56 {
    font-size: 3.5rem !important;
  }
  .u-fs-md-58 {
    font-size: 3.625rem !important;
  }
  .u-fs-md-60 {
    font-size: 3.75rem !important;
  }
  .u-fs-md-62 {
    font-size: 3.875rem !important;
  }
  .u-fs-md-64 {
    font-size: 4rem !important;
  }
  .u-fs-md-66 {
    font-size: 4.125rem !important;
  }
  .u-fs-md-68 {
    font-size: 4.25rem !important;
  }
  .u-fs-md-70 {
    font-size: 4.375rem !important;
  }
  .u-fs-md-72 {
    font-size: 4.5rem !important;
  }
  .u-fs-md-74 {
    font-size: 4.625rem !important;
  }
  .u-fs-md-76 {
    font-size: 4.75rem !important;
  }
  .u-fs-md-78 {
    font-size: 4.875rem !important;
  }
  .u-fs-md-80 {
    font-size: 5rem !important;
  }
}

@media (min-width: 992px) {
  .u-fs-lg-20 {
    font-size: 1.25rem !important;
  }
  .u-fs-lg-22 {
    font-size: 1.375rem !important;
  }
  .u-fs-lg-24 {
    font-size: 1.5rem !important;
  }
  .u-fs-lg-26 {
    font-size: 1.625rem !important;
  }
  .u-fs-lg-28 {
    font-size: 1.75rem !important;
  }
  .u-fs-lg-30 {
    font-size: 1.875rem !important;
  }
  .u-fs-lg-32 {
    font-size: 2rem !important;
  }
  .u-fs-lg-34 {
    font-size: 2.125rem !important;
  }
  .u-fs-lg-36 {
    font-size: 2.25rem !important;
  }
  .u-fs-lg-38 {
    font-size: 2.375rem !important;
  }
  .u-fs-lg-40 {
    font-size: 2.5rem !important;
  }
  .u-fs-lg-42 {
    font-size: 2.625rem !important;
  }
  .u-fs-lg-44 {
    font-size: 2.75rem !important;
  }
  .u-fs-lg-46 {
    font-size: 2.875rem !important;
  }
  .u-fs-lg-48 {
    font-size: 3rem !important;
  }
  .u-fs-lg-50 {
    font-size: 3.125rem !important;
  }
  .u-fs-lg-52 {
    font-size: 3.25rem !important;
  }
  .u-fs-lg-54 {
    font-size: 3.375rem !important;
  }
  .u-fs-lg-56 {
    font-size: 3.5rem !important;
  }
  .u-fs-lg-58 {
    font-size: 3.625rem !important;
  }
  .u-fs-lg-60 {
    font-size: 3.75rem !important;
  }
  .u-fs-lg-62 {
    font-size: 3.875rem !important;
  }
  .u-fs-lg-64 {
    font-size: 4rem !important;
  }
  .u-fs-lg-66 {
    font-size: 4.125rem !important;
  }
  .u-fs-lg-68 {
    font-size: 4.25rem !important;
  }
  .u-fs-lg-70 {
    font-size: 4.375rem !important;
  }
  .u-fs-lg-72 {
    font-size: 4.5rem !important;
  }
  .u-fs-lg-74 {
    font-size: 4.625rem !important;
  }
  .u-fs-lg-76 {
    font-size: 4.75rem !important;
  }
  .u-fs-lg-78 {
    font-size: 4.875rem !important;
  }
  .u-fs-lg-80 {
    font-size: 5rem !important;
  }
}

.u-mt-5 {
  margin-top: 0.3125rem !important;
}

.u-mt-10 {
  margin-top: 0.625rem !important;
}

.u-mt-15 {
  margin-top: 0.9375rem !important;
}

.u-mt-20 {
  margin-top: 1.25rem !important;
}

.u-mt-25 {
  margin-top: 1.5625rem !important;
}

.u-mt-30 {
  margin-top: 1.875rem !important;
}

.u-mt-35 {
  margin-top: 2.1875rem !important;
}

.u-mt-40 {
  margin-top: 2.5rem !important;
}

.u-mt-45 {
  margin-top: 2.8125rem !important;
}

.u-mt-50 {
  margin-top: 3.125rem !important;
}

.u-mt-55 {
  margin-top: 3.4375rem !important;
}

.u-mt-60 {
  margin-top: 3.75rem !important;
}

.u-mt-65 {
  margin-top: 4.0625rem !important;
}

.u-mt-70 {
  margin-top: 4.375rem !important;
}

.u-mt-75 {
  margin-top: 4.6875rem !important;
}

.u-mt-80 {
  margin-top: 5rem !important;
}

.u-mb-5 {
  margin-bottom: 0.3125rem !important;
}

.u-mb-10 {
  margin-bottom: 0.625rem !important;
}

.u-mb-15 {
  margin-bottom: 0.9375rem !important;
}

.u-mb-20 {
  margin-bottom: 1.25rem !important;
}

.u-mb-25 {
  margin-bottom: 1.5625rem !important;
}

.u-mb-30 {
  margin-bottom: 1.875rem !important;
}

.u-mb-35 {
  margin-bottom: 2.1875rem !important;
}

.u-mb-40 {
  margin-bottom: 2.5rem !important;
}

.u-mb-45 {
  margin-bottom: 2.8125rem !important;
}

.u-mb-50 {
  margin-bottom: 3.125rem !important;
}

.u-mb-55 {
  margin-bottom: 3.4375rem !important;
}

.u-mb-60 {
  margin-bottom: 3.75rem !important;
}

.u-mb-65 {
  margin-bottom: 4.0625rem !important;
}

.u-mb-70 {
  margin-bottom: 4.375rem !important;
}

.u-mb-75 {
  margin-bottom: 4.6875rem !important;
}

.u-mb-80 {
  margin-bottom: 5rem !important;
}

.u-ml-5 {
  margin-left: 0.3125rem !important;
}

.u-ml-10 {
  margin-left: 0.625rem !important;
}

.u-ml-15 {
  margin-left: 0.9375rem !important;
}

.u-ml-20 {
  margin-left: 1.25rem !important;
}

.u-ml-25 {
  margin-left: 1.5625rem !important;
}

.u-ml-30 {
  margin-left: 1.875rem !important;
}

.u-ml-35 {
  margin-left: 2.1875rem !important;
}

.u-ml-40 {
  margin-left: 2.5rem !important;
}

.u-ml-45 {
  margin-left: 2.8125rem !important;
}

.u-ml-50 {
  margin-left: 3.125rem !important;
}

.u-ml-55 {
  margin-left: 3.4375rem !important;
}

.u-ml-60 {
  margin-left: 3.75rem !important;
}

.u-ml-65 {
  margin-left: 4.0625rem !important;
}

.u-ml-70 {
  margin-left: 4.375rem !important;
}

.u-ml-75 {
  margin-left: 4.6875rem !important;
}

.u-ml-80 {
  margin-left: 5rem !important;
}

.u-pr-5 {
  padding-right: 0.3125rem !important;
}

.u-pr-10 {
  padding-right: 0.625rem !important;
}

.u-pr-15 {
  padding-right: 0.9375rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-25 {
  padding-right: 1.5625rem !important;
}

.u-pr-30 {
  padding-right: 1.875rem !important;
}

.u-pr-35 {
  padding-right: 2.1875rem !important;
}

.u-pr-40 {
  padding-right: 2.5rem !important;
}

.u-pr-45 {
  padding-right: 2.8125rem !important;
}

.u-pr-50 {
  padding-right: 3.125rem !important;
}

.u-pr-55 {
  padding-right: 3.4375rem !important;
}

.u-pr-60 {
  padding-right: 3.75rem !important;
}

.u-pr-65 {
  padding-right: 4.0625rem !important;
}

.u-pr-70 {
  padding-right: 4.375rem !important;
}

.u-pr-75 {
  padding-right: 4.6875rem !important;
}

.u-pr-80 {
  padding-right: 5rem !important;
}

.u-my-5 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.u-my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.u-my-15 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.u-my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.u-my-25 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important;
}

.u-my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.u-my-35 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.u-my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.u-my-45 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.u-my-50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.u-my-55 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important;
}

.u-my-60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.u-my-65 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important;
}

.u-my-70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.u-my-75 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important;
}

.u-my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.u-my-100 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.u-mx-5 {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important;
}

.u-mx-6 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.u-mx-7 {
  margin-left: 0.4375rem !important;
  margin-right: 0.4375rem !important;
}

.u-mx-8 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.u-mx-9 {
  margin-left: 0.5625rem !important;
  margin-right: 0.5625rem !important;
}

.u-mx-10 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.u-mx-11 {
  margin-left: 0.6875rem !important;
  margin-right: 0.6875rem !important;
}

.u-mx-12 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.u-mx-13 {
  margin-left: 0.8125rem !important;
  margin-right: 0.8125rem !important;
}

.u-mx-14 {
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.u-mx-15 {
  margin-left: 0.9375rem !important;
  margin-right: 0.9375rem !important;
}

.u-mx-16 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.u-mx-17 {
  margin-left: 1.0625rem !important;
  margin-right: 1.0625rem !important;
}

.u-mx-18 {
  margin-left: 1.125rem !important;
  margin-right: 1.125rem !important;
}

.u-mx-19 {
  margin-left: 1.1875rem !important;
  margin-right: 1.1875rem !important;
}

.u-mx-20 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.u-mx-21 {
  margin-left: 1.3125rem !important;
  margin-right: 1.3125rem !important;
}

.u-mx-22 {
  margin-left: 1.375rem !important;
  margin-right: 1.375rem !important;
}

.u-mx-23 {
  margin-left: 1.4375rem !important;
  margin-right: 1.4375rem !important;
}

.u-mx-24 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.u-mx-25 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important;
}

.u-mx-26 {
  margin-left: 1.625rem !important;
  margin-right: 1.625rem !important;
}

.u-mx-27 {
  margin-left: 1.6875rem !important;
  margin-right: 1.6875rem !important;
}

.u-mx-28 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.u-mx-29 {
  margin-left: 1.8125rem !important;
  margin-right: 1.8125rem !important;
}

.u-mx-30 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

.u-mx-31 {
  margin-left: 1.9375rem !important;
  margin-right: 1.9375rem !important;
}

.u-mx-32 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.u-mx-33 {
  margin-left: 2.0625rem !important;
  margin-right: 2.0625rem !important;
}

.u-mx-34 {
  margin-left: 2.125rem !important;
  margin-right: 2.125rem !important;
}

.u-mx-35 {
  margin-left: 2.1875rem !important;
  margin-right: 2.1875rem !important;
}

.u-mx-36 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.u-mx-37 {
  margin-left: 2.3125rem !important;
  margin-right: 2.3125rem !important;
}

.u-mx-38 {
  margin-left: 2.375rem !important;
  margin-right: 2.375rem !important;
}

.u-mx-39 {
  margin-left: 2.4375rem !important;
  margin-right: 2.4375rem !important;
}

.u-mx-40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.u-mx-41 {
  margin-left: 2.5625rem !important;
  margin-right: 2.5625rem !important;
}

.u-mx-42 {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important;
}

.u-mx-43 {
  margin-left: 2.6875rem !important;
  margin-right: 2.6875rem !important;
}

.u-mx-44 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.u-mx-45 {
  margin-left: 2.8125rem !important;
  margin-right: 2.8125rem !important;
}

.u-mx-46 {
  margin-left: 2.875rem !important;
  margin-right: 2.875rem !important;
}

.u-mx-47 {
  margin-left: 2.9375rem !important;
  margin-right: 2.9375rem !important;
}

.u-mx-48 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.u-mx-49 {
  margin-left: 3.0625rem !important;
  margin-right: 3.0625rem !important;
}

.u-mx-50 {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

.u-mx-51 {
  margin-left: 3.1875rem !important;
  margin-right: 3.1875rem !important;
}

.u-mx-52 {
  margin-left: 3.25rem !important;
  margin-right: 3.25rem !important;
}

.u-mx-53 {
  margin-left: 3.3125rem !important;
  margin-right: 3.3125rem !important;
}

.u-mx-54 {
  margin-left: 3.375rem !important;
  margin-right: 3.375rem !important;
}

.u-mx-55 {
  margin-left: 3.4375rem !important;
  margin-right: 3.4375rem !important;
}

.u-mx-56 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.u-mx-57 {
  margin-left: 3.5625rem !important;
  margin-right: 3.5625rem !important;
}

.u-mx-58 {
  margin-left: 3.625rem !important;
  margin-right: 3.625rem !important;
}

.u-mx-59 {
  margin-left: 3.6875rem !important;
  margin-right: 3.6875rem !important;
}

.u-mx-60 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.u-mx-61 {
  margin-left: 3.8125rem !important;
  margin-right: 3.8125rem !important;
}

.u-mx-62 {
  margin-left: 3.875rem !important;
  margin-right: 3.875rem !important;
}

.u-mx-63 {
  margin-left: 3.9375rem !important;
  margin-right: 3.9375rem !important;
}

.u-mx-64 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.u-mx-65 {
  margin-left: 4.0625rem !important;
  margin-right: 4.0625rem !important;
}

.u-mx-66 {
  margin-left: 4.125rem !important;
  margin-right: 4.125rem !important;
}

.u-mx-67 {
  margin-left: 4.1875rem !important;
  margin-right: 4.1875rem !important;
}

.u-mx-68 {
  margin-left: 4.25rem !important;
  margin-right: 4.25rem !important;
}

.u-mx-69 {
  margin-left: 4.3125rem !important;
  margin-right: 4.3125rem !important;
}

.u-mx-70 {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important;
}

.u-mx-71 {
  margin-left: 4.4375rem !important;
  margin-right: 4.4375rem !important;
}

.u-mx-72 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.u-mx-73 {
  margin-left: 4.5625rem !important;
  margin-right: 4.5625rem !important;
}

.u-mx-74 {
  margin-left: 4.625rem !important;
  margin-right: 4.625rem !important;
}

.u-mx-75 {
  margin-left: 4.6875rem !important;
  margin-right: 4.6875rem !important;
}

.u-mx-76 {
  margin-left: 4.75rem !important;
  margin-right: 4.75rem !important;
}

.u-mx-77 {
  margin-left: 4.8125rem !important;
  margin-right: 4.8125rem !important;
}

.u-mx-78 {
  margin-left: 4.875rem !important;
  margin-right: 4.875rem !important;
}

.u-mx-79 {
  margin-left: 4.9375rem !important;
  margin-right: 4.9375rem !important;
}

.u-mx-80 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

@media (min-width: 768px) {
  .u-mt-md-80 {
    margin-top: 5rem !important;
  }
  .u-mt-md-75 {
    margin-top: 4.6875rem !important;
  }
  .u-mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .u-mt-md-65 {
    margin-top: 4.0625rem !important;
  }
  .u-mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .u-mt-md-55 {
    margin-top: 3.4375rem !important;
  }
  .u-mt-md-50 {
    margin-top: 3.125rem !important;
  }
  .u-mt-md-45 {
    margin-top: 2.8125rem !important;
  }
  .u-mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .u-mt-md-35 {
    margin-top: 2.1875rem !important;
  }
  .u-mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .u-mt-md-25 {
    margin-top: 1.5625rem !important;
  }
  .u-mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .u-mt-md-15 {
    margin-top: 0.9375rem !important;
  }
  .u-mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .u-mb-md-70 {
    margin-bottom: 2.3125rem !important;
  }
  .u-mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .u-mb-md-85 {
    margin-bottom: 5.3125rem !important;
  }
  .u-mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .u-mb-md-95 {
    margin-bottom: 5.9375rem !important;
  }
  .u-mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .u-ml-md-80 {
    margin-left: 5rem !important;
  }
  .u-ml-md-85 {
    margin-left: 5.3125rem !important;
  }
  .u-ml-md-90 {
    margin-left: 5.625rem !important;
  }
  .u-ml-md-95 {
    margin-left: 5.9375rem !important;
  }
  .u-ml-md-100 {
    margin-left: 6.25rem !important;
  }
  .u-mr-md-80 {
    margin-right: 5rem !important;
  }
  .u-mr-md-85 {
    margin-right: 5.3125rem !important;
  }
  .u-mr-md-90 {
    margin-right: 5.625rem !important;
  }
  .u-mr-md-95 {
    margin-right: 5.9375rem !important;
  }
  .u-mr-md-100 {
    margin-right: 6.25rem !important;
  }
  .u-my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .u-my-md-85 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important;
  }
  .u-my-md-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .u-my-md-95 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important;
  }
  .u-my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .u-mx-md-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .u-mx-md-81 {
    margin-left: 5.0625rem !important;
    margin-right: 5.0625rem !important;
  }
  .u-mx-md-82 {
    margin-left: 5.125rem !important;
    margin-right: 5.125rem !important;
  }
  .u-mx-md-83 {
    margin-left: 5.1875rem !important;
    margin-right: 5.1875rem !important;
  }
  .u-mx-md-84 {
    margin-left: 5.25rem !important;
    margin-right: 5.25rem !important;
  }
  .u-mx-md-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .u-mx-md-86 {
    margin-left: 5.375rem !important;
    margin-right: 5.375rem !important;
  }
  .u-mx-md-87 {
    margin-left: 5.4375rem !important;
    margin-right: 5.4375rem !important;
  }
  .u-mx-md-88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }
  .u-mx-md-89 {
    margin-left: 5.5625rem !important;
    margin-right: 5.5625rem !important;
  }
  .u-mx-md-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .u-mx-md-91 {
    margin-left: 5.6875rem !important;
    margin-right: 5.6875rem !important;
  }
  .u-mx-md-92 {
    margin-left: 5.75rem !important;
    margin-right: 5.75rem !important;
  }
  .u-mx-md-93 {
    margin-left: 5.8125rem !important;
    margin-right: 5.8125rem !important;
  }
  .u-mx-md-94 {
    margin-left: 5.875rem !important;
    margin-right: 5.875rem !important;
  }
  .u-mx-md-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .u-mx-md-96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .u-mx-md-97 {
    margin-left: 6.0625rem !important;
    margin-right: 6.0625rem !important;
  }
  .u-mx-md-98 {
    margin-left: 6.125rem !important;
    margin-right: 6.125rem !important;
  }
  .u-mx-md-99 {
    margin-left: 6.1875rem !important;
    margin-right: 6.1875rem !important;
  }
  .u-mx-md-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
}

.card {
  border: none !important;
}

.tab__slider > .tab-pane {
  display: block;
  height: 0;
  overflow: visible;
}

.tab__slider > .active {
  height: auto;
}

.form-control {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.input-group .form-control {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.lead {
  font-size: 22px;
}

.progress {
  background: #d6ebff;
}
.progress-bar {
  height: 8px;
  background: #3564ff !important;
}

.slick-arrow {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-size: 24px;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.slick-arrow:hover {
  background: #3564ff;
  color: #fff;
}

.slick-prev {
  left: -70px;
}

.slick-next {
  right: -70px;
}

.slick-dots {
  padding: 0;
  list-style: none;
}
.slick-dots > li {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}
.slick-dots > li > span {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: rgba(0, 129, 255, 0.5);
  display: block;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}
.slick-dots > li.slick-active > span {
  width: 22px;
  background: #3564ff;
}
.slick-dots.dots-white > li > span {
  background: rgba(255, 255, 255, 0.7);
}
.slick-dots.dots-white > li.slick-active span {
  background: #fff;
}
.slick-dots.dots-green > li > span {
  background: rgba(44, 212, 74, 0.7);
}
.slick-dots.dots-green > li.slick-active span {
  background: #2cd44a;
}

.navbar {
  padding: 10px 0;
}

.navbar-light .navbar-brand {
  color: #000;
  font-size: 27px;
  font-weight: 900;
}

.navbar-light .navbar-nav .nav-link {
  color: #3e4348 !important;
}

.align-items-right {
  justify-content: flex-end;
  line-height: 0.1em;
  padding-top: 5px;
}

.align-text-left h1,
h2,
h4,
p {
  text-align: left !important;
}

.about_hero {
  background: url(/static/media/aboutus_bg.9bc9e0c9.webp);
  background-size: cover;
  background-position: center center;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  /*  width: 60px;*/
  /*height: 60px;*/
  bottom: 30px;
  right: 35px;
  background-color: #4fce5d;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 10px;
  margin-left: 7px;
}

.whatsapp_badge {
  background-color: #22891f;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
    margin-left: 7px;
  }

  .whatsapp_float {
    /*width: 40px;*/
    /*height: 40px;*/
    bottom: 15px;
    right: 25px;
    font-size: 16px;
  }
}

.button__badge {
  background-color: #fa3e3e;
  border-radius: 15px;
  color: white;
  padding: 1px 11px;
  font-size: 12px;
  position: absolute;
  top: 15;
  right: 5;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
  margin-top: auto;
  margin-bottom: auto;
}
.text-sm {
  font-size: 12px;
}
.price {
  font-size: 3em;
}

.pricing-table td.is-highlighted,
.pricing-table th.is-highlighted {
  background-color: #e9f6e8;
}

.mobileHide {
  display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 280px) and (max-device-width: 961px) {
  .mobileHide {
    display: none;
  }
}

.mobileShow {
  display: none;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 280px) and (max-device-width: 961px) {
  .mobileShow {
    display: inline;
  }
}

.zoho-demo-form {
  height: 650px;
  width: 70%;
  border: none;
  justify-content: center !important;
}

@media (max-width: 575.98px) {
  .zoho-demo-form {
    width: 95%;
  }
}

#workAddress {
  display: none;
}

@media (max-width: 575.98px) {
  .videoContainerMobile {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 575.98px) {
  .u-pull-up {
    top: -30px;
  }
}

.main-shadow {
  box-shadow: 2px 1px 36px rgb(0 0 0 / 32%);
}
.card {
  transition: transform 0.2s ease;
  box-shadow: 0px 10px 20px 0px rgba(22, 22, 26, 0.12);
  border-radius: 0;
  border: 0;
  margin-bottom: 1.5em;
}

/* .card:hover {
  transform: scale(1.1);
} */

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  overflow: hidden;
}

.video {
  pointer-events: none;
  width: 100%;
}

.mobileFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999;
  background-color: #fff;
  box-shadow: 0 1rem 1rem 1.5rem rgba(0, 0, 0, 0.1) !important;
}

.font-face-ar h1 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar h4 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar h2 {
  text-align: right;
  font-weight: 500;
}

.font-face-ar p {
  text-align: right;
  font-weight: 500;
}

.featureHeader {
  font-size: 90px;
  padding-top: 20px !important;
}

@media (max-width: 575.98px) {
  .featureHeader {
    font-size: 50px;
    padding-top: 20px !important;
    font-weight: 600 !important;
  }
}

.navbar > .container-fluid .navbar-brand {
  margin-left: 0;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}

.text-light-black {
  color: #303030;
}

.features {
  padding: 15px;
  margin-top: 30px;
}

.features img {
  width: 100%;
  object-fit: cover;
  /* background-color: white; */
}

.features .top-sec {
  margin-top: -100px;
  margin-bottom: 15px;
}

input[type='file'] {
  width: 0;
  height: 0;
  z-index: -1;
  position: absolute;
  overflow: hidden;
  opacity: 0;
}

@media (max-width: 991px) {
  #navbarNav {
    background: #f8f8f8;
    max-height: 100vh;
    margin-top: 100;
    padding: 20px;
    overflow: auto;
  }
  #navbarNav .nav-link {
    color: #54667a;
  }
}

.navbar-default {
  background-color: #69899f;
  background-image: linear-gradient(to bottom, #fff 0%, #fff 94%);
  box-shadow: none;
}

.suggestion-item {
  padding: 10px;
  text-align: left;
  background-color: #fafafa;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 10px;
  text-align: left;
  background-color: #bababa;
  cursor: pointer;
}

.panel {
  background-color: #ececec6b;
}

.panel-footer {
  background-color: #ececec6b;
}

.timeline {
  background-color: #fff;
}

.white-bg {
  background-color: #fff;
}
.pad-top-45 {
  padding-top: 45px;
}

.pad-top-35 {
  padding-top: 35px;
}

.pad-top-30 {
  padding-top: 30px;
}

.fileupload {
  position: relative;
  overflow: hidden;
}

.text-height-0 {
  line-height: 1 !important;
}

.text-height-1 {
  line-height: 1.4 !important;
}

.borderedCard {
  border: 2px solid #e9eff5;
  border-radius: 4px;
}

.fa-stack:after {
  position: absolute;
  right: 10%;
  top: 30%;
  content: attr(data-count);
  font-size: 30%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: #66b400;
  min-width: 1em;
  font-weight: bold;
}

.darkBorder {
  border: 1px solid #525252 !important;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.67em + 1.57rem + 2px);
  padding: 0.785rem 2.67rem 0.785rem 1.67rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.67;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 1.67rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 0.7rem 0.8rem rgba(0, 0, 0, 0.1);
}

.main {
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2) !important;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0;
}

.pricing .card-discounted-price {
  font-size: 1.15rem;
  text-decoration: line-through;
  color: #fc3158 !important;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.7rem 1rem 0 rgba(0, 0, 0, 0.5) !important;
  }

  .pricing .card:hover .btn {
    opacity: 1 !important;
  }

  .card .btn-primary:hover {
    background: #4f64ff;
    border-color: #4f64ff;
  }
}

.Modal {
  position: absolute;
  top: 10px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #4f64ff;
  border-radius: 15px;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ModalClose {
  position: absolute;
  top: 10px;
  right: 0px;
}

.offerModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 200px;
  max-height: 400px;
  border-radius: 15px;
  background-color: #fff;
}

@media (max-width: 991px) {
  .offerModal {
    min-height: calc(50% - 1rem);
    min-width: calc(100% - 1rem);
  }
}
.offerModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.offerModalClose {
  padding: 10px;
  align-items: flex-start;
}

.faq-row-wrapper {
  max-width: 700px;
}
.faq-title {
  justify-content: center !important;
  border-bottom: none !important;
}
.faq-body {
  padding-top: 20px;
}

@media (max-width: 991px) {
  .faq-body {
    padding-top: 20px;
  }
}

.row-content-text {
  padding: 10px !important;
}

